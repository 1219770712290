import { useSDK } from '@features/app-deps-provider'
import { RequireLogin } from '@features/auth/require-login'
import { OrganizationLoginPage } from '@features/organizations'
import { BrandLogoIcon, Button, CheckIcon, FullPageCard } from '@st/theme'
import { clsx } from 'clsx'
import { useState } from 'react'

type Props = {
  clientId: string
  state: string
  redirectUri: string
}
export function AuthorizeKarbonPage({ clientId, state, redirectUri }: Props) {
  return (
    <RequireLogin
      loginScreen={<OrganizationLoginPage header={<KarbonLoginHeader />} />}
      loadingScreen={<></>}
    >
      <LoggedInAuthorizeKarbon clientId={clientId} state={state} redirectUri={redirectUri} />
    </RequireLogin>
  )
}

function LoggedInAuthorizeKarbon({ clientId, state, redirectUri }: Props) {
  const sdk = useSDK()

  const [isAuthorizing, setIsAuthorizing] = useState(false)

  async function onClickAuthorize() {
    setIsAuthorizing(true)
    // const response = await sdk.send({
    //   type: 'accounts/authorizeKarbon',
    //   clientId,
    //   state,
    //   redirectUri
    // })
    // window.location.href = response.redirectTo
  }

  return (
    <FullPageCard>
      <div className="flex flex-col items-center gap-3">
        <StanfordTaxKarbonHeader />

        <p className="mt-4 text-center">Karbon wants to access StanfordTax</p>

        <ul className="my-6 w-full space-y-4 px-4">
          <li className="flex gap-x-3 text-base">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
            Client questionnnaire data
          </li>

          <li className="flex gap-x-3 text-base">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
            Client document uploads
          </li>

          <li className="flex gap-x-3 text-base">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
            Preparer workpapers
          </li>

          <li className="flex gap-x-3 text-base">
            <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-blue-600" />
            Notifications for document uploads and questionnaire submissions
          </li>
        </ul>

        <Button size="m" className="mt-4 w-full" variant="primary" onClick={onClickAuthorize}>
          {isAuthorizing ? 'Authorizing...' : 'Authorize Karbon'}
        </Button>
      </div>
    </FullPageCard>
  )
}

export function KarbonLoginHeader() {
  return (
    <div className="flex flex-col items-center gap-3">
      <StanfordTaxKarbonHeader />
      <p className="mt-4 text-center">Login to grant Karbon access to StanfordTax</p>
    </div>
  )
}

function StanfordTaxKarbonHeader({ className }: { className?: string }) {
  return (
    <div className={clsx('flex items-center gap-2', className)}>
      <img src="/icons/karbon-logo.png" className="size-12 rounded-md" />
      <ConnectArrow />
      <BrandLogoIcon />
    </div>
  )
}

function ConnectArrow() {
  return (
    <svg viewBox="0 0 24 24" className="size-6">
      <path
        fillRule="evenodd"
        d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
