export type ISO8601Time = string
export type Timestamp = number

export function formatISO8601Date(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export function formatISO8601Time(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

export function formatISO8601DateTime(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

/**
 * Parse an ISO8601 timestamp that's cross-browser compatible.
 */
export function parseISO8601Time(iso8601string: string): Date {
  // Safari doesn't accept ISO8601 strings if the date and time are separated by a space
  // It must contain a T
  // See more: https://stackoverflow.com/a/60391306
  return new Date(iso8601string.replace(/\s+/g, 'T'))
}

export function timestampToDate(timestamp: Timestamp) {
  return new Date(timestamp * 1000)
}

export function timestampToISO8601(timestamp: Timestamp): ISO8601Time {
  return new Date(timestamp * 1000).toISOString()
}

export function iso8601ToTimestamp(iso8601string: ISO8601Time): Timestamp {
  const date = parseISO8601Time(iso8601string)
  return Math.floor(date.getTime() / 1000)
}

export function formatISO8601RelativeDateTime(time: ISO8601Time, locale = 'en'): string {
  const date = parseISO8601Time(time)
  const now = new Date()
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)

  // Create a formatter that will output relative time (e.g., "yesterday", "2 days ago")
  const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })

  // Format the time based on how long ago it was
  if (diffInDays === 1) {
    // If it was yesterday
    return relativeTimeFormatter.format(-1, 'day') // "yesterday"
  } else if (diffInDays > 1 && diffInDays < 3) {
    // If it was 2 days ago
    return relativeTimeFormatter.format(-diffInDays, 'day') // "2 days ago"
  } else if (diffInDays >= 3) {
    // If it was 3 or more days ago, use the date format (e.g., "Jan 1, 2023")
    return formatISO8601Date(time)
  } else if (diffInHours > 0) {
    // If it was hours ago
    return relativeTimeFormatter.format(-diffInHours, 'hour') // "X hours ago"
  } else if (diffInMinutes > 0) {
    // If it was minutes ago
    return relativeTimeFormatter.format(-diffInMinutes, 'minute') // "X minutes ago"
  } else {
    // If it was less than a minute ago
    return relativeTimeFormatter.format(-1, 'minute') // "1 minute ago" (just now)
  }
}
