import { useProcessState } from '@st/redux'
import { Subscription } from '@st/sdk'
import { formatMoney } from '@st/util/format'
import { match } from 'ts-pattern'
import { organizationBillingModule } from './organization-billing-module'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'
import { useOrganization } from '../organizations'
import { useProcess } from '@st/redux'

export function STOrganizationPlanSettingsPage() {
  const organization = useOrganization()

  useProcess(organizationBillingModule, { organizationId: organization.id })
  const organizationBillingState = useProcessState(organizationBillingModule, (s) => s)

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Your plan</h2>

      {match(organizationBillingState)
        .with({ status: 'loading' }, () => null)
        .with({ status: 'loaded' }, ({ billingState }) => {
          if (!billingState.activeSubscription) {
            return <div>You are currently on the free plan which includes 5 free returns.</div>
          }

          return (
            <SubscriptionCard
              subscription={billingState.activeSubscription}
              foldersUsed={billingState.folderUsageByYear[2025] ?? 0}
            />
          )
        })
        .otherwise(() => null)}
    </OrganizationSettingsScaffold>
  )
}

function SubscriptionCard({
  subscription,
  foldersUsed
}: {
  subscription: Subscription
  foldersUsed: number
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <label className="text-base font-semibold">Annual price</label>
        {formatMoney(subscription.basePrice)}/year, {subscription.baseUnitsIncluded} returns
        included
      </div>
      <div className="flex flex-col">
        <label className="text-base font-semibold">Price for each additional return</label>
        {formatMoney(subscription.additionalUnitPrice)}/return
      </div>
      <div className="flex flex-col">
        <label className="text-base font-semibold">Current usage</label>
        {foldersUsed}
      </div>
    </div>
  )
}
