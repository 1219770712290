import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset as floatingOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { ReactNode, cloneElement, isValidElement, useState } from 'react'

type Offset = {
  dx?: number
  dy?: number
}

type PopoverMenuProps = {
  trigger: ReactNode
  children: ReactNode
  placement?: 'bottom-start' | 'bottom-end' | 'bottom' | 'top-start' | 'top-end' | 'top'
  isControlled?: boolean
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  offset?: Offset
}

export function PopoverMenu({
  trigger,
  children,
  placement = 'bottom-end',
  offset = { dx: 0, dy: 0 },
  isControlled = false,
  isOpen: controlledIsOpen,
  onOpenChange: controlledOnOpenChange
}: PopoverMenuProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false)

  const isOpen = isControlled ? controlledIsOpen : uncontrolledIsOpen
  const onOpenChange = isControlled ? controlledOnOpenChange : setUncontrolledIsOpen

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange,
    placement,
    middleware: [floatingOffset({ mainAxis: offset.dy, crossAxis: offset.dx }), flip(), shift()],
    whileElementsMounted: autoUpdate
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'dialog' })

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  return (
    <>
      {isValidElement(trigger) ? (
        cloneElement(trigger, {
          ref: refs.setReference,
          ...getReferenceProps(),
          ...trigger.props
        })
      ) : (
        <div ref={refs.setReference} {...getReferenceProps()}>
          {trigger}
        </div>
      )}

      {isOpen && (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps({})}>
            {children}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
