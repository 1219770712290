import { useProcess, useProcessState } from '@st/redux'
import { STDocument } from '@st/sdk'
import {
  Button,
  DocumentSplitDialog,
  DocumentSplitList,
  DocumentSplitListItem,
  PopoverMenu,
  TagChip
} from '@st/theme'
import { Anchor } from '@st/util/geom'
import { deepEqual } from '@st/util/json-value'
import { clsx } from 'clsx'
import { useState } from 'react'
import { pdfViewerModule } from '../st-pdf-viewer/module'
import {
  selFolderOperationInProgress,
  selAlreadySplitDocuments,
  stFolderModule
} from './st-folder-module'

type Props = {
  doc: STDocument
}
export function SplitDocumentBanner({ doc }: Props) {
  const pdfViewer = useProcess(pdfViewerModule)

  const stFolder = useProcess(stFolderModule)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const documentTypes = useProcessState(stFolderModule, (s) => s.folderState!.documentTypes ?? [])
  const isSplitting = useProcessState(stFolderModule, (s) =>
    selFolderOperationInProgress(s, 'folders/splitBundledDocument')
  )
  const alreadySplitDocuments = useProcessState(stFolderModule, (s) =>
    selAlreadySplitDocuments(s, doc.id)
  )

  const [ranges, setRanges] = useState(
    doc.splits
      .map((split) => split.pageRange)
      .filter((split) => !alreadySplitDocuments.some((d) => deepEqual(d.source?.pageRange, split)))
  )

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={clsx(
        'pointer-events-auto flex items-center justify-between gap-x-6 bg-yellow-300 px-5 text-base text-yellow-900'
      )}
    >
      We detected multiple documents bundled into one. You can automatically split this document to
      separate documents.
      <PopoverMenu
        isControlled={true}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        offset={{ dy: 8 }}
        trigger={
          <Button variant="default" className="bg-white">
            Split document
          </Button>
        }
      >
        <DocumentSplitDialog
          title="Split document"
          subtitle="Select the documents you would like to extract into separate files. This original document will remain."
          buttons={
            <>
              <Button variant="subtle" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  stFolder.send({
                    type: 'request',
                    request: {
                      type: 'folders/splitBundledDocument',
                      folderId: folderId,
                      sourceDocumentId: doc.id,
                      ranges: ranges
                    }
                  })
                }}
                disabled={isSplitting || ranges.length === 0}
              >
                {isSplitting ? 'Splitting...' : 'Split document'}
              </Button>
            </>
          }
        >
          <DocumentSplitList>
            {doc.splits.map((split, index) => {
              const label =
                documentTypes.find((dt) => dt.id === split.inferredDocumentTypeId)?.name ??
                'Uncategorized'

              const selected = ranges.some((r) => deepEqual(r, split.pageRange))

              const existingSplitDocument = alreadySplitDocuments.find((d) =>
                deepEqual(d.source?.pageRange, split.pageRange)
              )

              return (
                <DocumentSplitListItem
                  key={index}
                  title={`Document ${index + 1}`}
                  description={`${label} | ${formatPageRange(split.pageRange)}`}
                  selected={selected}
                  tag={
                    existingSplitDocument ? (
                      <TagChip
                        color="green"
                        onClick={() =>
                          stFolder.send({
                            type: 'docSelected',
                            docId: existingSplitDocument.id
                          })
                        }
                      >
                        Already split
                      </TagChip>
                    ) : null
                  }
                  onClickJump={() => {
                    pdfViewer.send({
                      type: 'scrollTo',
                      pageIndex: split.pageRange.first - 1,
                      viewportAnchor: Anchor.topLeft,
                      targetAnchor: Anchor.topLeft,
                      targetRect: { x: 0, y: 0, width: 0, height: 0 }
                    })
                  }}
                  onToggleSelected={() => {
                    if (selected) {
                      const newRanges = ranges.filter((r) => !deepEqual(r, split.pageRange))
                      setRanges(newRanges)
                    } else {
                      setRanges([...ranges, split.pageRange].toSorted((a, b) => a.first - b.first))
                    }
                  }}
                />
              )
            })}
          </DocumentSplitList>
        </DocumentSplitDialog>
      </PopoverMenu>
    </div>
  )
}

function formatPageRange(pageRange: { first: number; last: number }) {
  return pageRange.first === pageRange.last
    ? `Page ${pageRange.first}`
    : `Pages ${pageRange.first} - ${pageRange.last}`
}
