import { defineModule } from '@st/redux'
import { Anchor, NormalizedRect, Point, Rect, Size } from '@st/util/geom'
import { PlatformMessage, ShortcutTriggered } from './platform-module'
import { DocAnnotation } from '@features/st-doc-viewer/field-types'

export const HIGHLIGHT_COLOR_OPTIONS = ['#FFCD45', '#E6750C', '#59BE8D']
export const RECT_COLOR_OPTIONS = ['#FF0000', '#FFCD45', '#59BE8D']
export const TEXT_COLOR_OPTIONS = ['#FF0000', '#FFCD45', '#59BE8D']

const INITIAL_STATE: ViewerState = {
  status: 'loading',
  error: undefined,
  pages: [],
  selectedPage: 0,
  zoomLevel: 1,
  textSelection: undefined
}

export const pdfViewerModule = defineModule<
  ViewerState,
  PDFViewerMessage,
  undefined,
  { platform: PlatformMessage; self: PDFViewerMessage }
>({
  name: 'pdfViewer',
  init: () => [
    INITIAL_STATE,
    {
      platform: {
        type: 'subscribeToShortcuts',
        shortcuts: ['$mod+c', 'Escape']
      }
    }
  ],
  handle: (state: ViewerState, message: PDFViewerMessage) => {
    switch (message.type) {
      case 'loaded':
        return {
          ...state,
          status: 'loaded',
          pages: message.pages,
          selectedPage: 1,
          zoomLevel: message.zoomLevel
        }
      case 'loadFailed':
        return {
          ...state,
          status: 'error',
          error: { type: 'error', message: message.error.message, src: message.error.src }
        }
      case 'pageSelected':
        return { ...state, selectedPage: message.page }
      case 'textSelected':
        return { ...state, textSelection: message.textSelections }
      case 'textDeselected':
        return { ...state, textSelection: undefined }
      case 'zoomUpdated':
        return { ...state, zoomLevel: message.zoomLevel }
      // commands
      case 'copySelectedText':
        const selectedText = selSelectedText(state)
        if (!selectedText) {
          return state
        }
        return [state, { platform: { type: 'copyToClipboard', text: selectedText } }]
      case 'shortcutTriggered':
        switch (message.shortcut) {
          case '$mod+c':
            return [state, { self: { type: 'copySelectedText' } }]
        }
      default:
        return state
    }
  }
})

export type TextSelection = {
  pageIndex: number
  rect: NormalizedRect
  text: string
}

type ViewerState = {
  status: 'loading' | 'loaded' | 'error'
  error: PDFViewerError | undefined
  pages: Page[]
  selectedPage: number
  zoomLevel: number
  textSelection: TextSelection[] | undefined
}

export type Page = {
  index: number
  size: Size
}

export type PDFViewerMessage =
  | {
      type: 'loaded'
      pages: Page[]
      zoomLevel: number
    }
  // event reactions
  | { type: 'loadFailed'; error: { type: string; message: string; src: string } }
  | { type: 'pageSelected'; page: number }
  | { type: 'zoomUpdated'; zoomLevel: number }
  | { type: 'textSelected'; textSelections: TextSelection[] }
  | { type: 'textDeselected' }
  // commands
  | { type: 'copySelectedText' }
  | { type: 'setZoom'; zoom: number }
  | { type: 'setTool'; tool: 'select' | 'none' }
  | {
      type: 'scrollTo'
      pageIndex: number
      viewportAnchor: Anchor
      targetAnchor: Anchor
      targetRect: Rect
    }
  | ShortcutTriggered

export type PDFViewerError = {
  type: string
  src: string
  message: string
}

export function selZoomLevel(state: ViewerState) {
  return state.zoomLevel
}

export function selPageSelection(state: ViewerState, pageIndex: number) {
  if (!state.textSelection) return undefined
  return state.textSelection.find((p) => p.pageIndex == pageIndex)
}

export function selSelectedText(state: ViewerState) {
  if (!state.textSelection) return undefined
  return state.textSelection.map((sel) => sel.text).join('\n')
}

export function canDeleteAnnotation(annotation: DocAnnotation) {
  if (annotation.type == 'field') {
    return false
  }
  return true
}
