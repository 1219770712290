import { ReactNode } from 'react'
import { clsx } from 'clsx'
import { PopoverMenu } from './PopoverMenu'

type IconButtonMenuProps = {
  icon: ReactNode
  children: ReactNode
  buttonClassName?: string
  contentClassName?: string
  placement?: 'bottom-start' | 'bottom-end' | 'bottom' | 'top-start' | 'top-end' | 'top'
  isControlled?: boolean
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
}

export function IconButtonMenu({
  icon,
  children,
  buttonClassName,
  placement = 'bottom-end',
  isControlled,
  isOpen,
  onOpenChange
}: IconButtonMenuProps) {
  return (
    <PopoverMenu
      trigger={
        <button
          aria-label="Toggle menu"
          className={clsx(
            'flex h-8 w-8 items-center justify-center rounded bg-stone-200 text-gray-900 outline-none hover:bg-stone-300',
            buttonClassName
          )}
        >
          {icon}
        </button>
      }
      placement={placement}
      isControlled={isControlled}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      {children}
    </PopoverMenu>
  )
}
