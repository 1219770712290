import { TagChipColor, TagIcon } from '@st/theme'

import { FolderTag } from '@st/sdk/src/sdk.types'
import { TagChip } from '@st/theme/src/TagChip'
import { isNotEmpty } from '@st/util/json-value'
import { ReactNode } from 'react'
import { STFolderAction } from '../st-folder-list/st-folder-list-module'

export function FolderTagsList({
  leading,
  folderTags,
  tagIds,
  onClick
}: {
  leading?: ReactNode
  folderTags: FolderTag[]
  tagIds: string[]
  onClick?: () => void
}) {
  const tags = tagIds.map((tid) => folderTags.find((t) => t.id == tid)).filter(isNotEmpty)

  return (
    <div className="flex flex-row gap-1" onClick={onClick}>
      {leading}
      {tags.map((t) => {
        return (
          <TagChip key={t.id} color={t.color as TagChipColor}>
            {t.label}
          </TagChip>
        )
      })}
    </div>
  )
}

export function FolderTagsListEditButton({
  onClick,
  className
}: {
  onClick: () => void
  className?: string
}) {
  return (
    <button
      className="invisible hover:text-blue-500 group-hover:visible"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
      }}
    >
      <TagIcon className="h-4 w-4" />
    </button>
  )
}
