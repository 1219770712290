import { FormationDocument } from '@st/formation'
import { FieldComment, PDFElementDocViewer, ReadWriteProvider } from '@st/pdf'
import { useProcessState } from '@st/redux'
import { STDocument } from '@st/sdk'
import { getFormConfig } from '@st/tax-folder'
import { FormConfig } from '@st/ui-config'
import { toStr } from '@st/util/cast'
import { parseMimeType } from '@st/util/mime'
import { usePromise } from '@util/promise'
import { useMemo } from 'react'
import { useFieldDecoration, useInputsWrite, useRead, useReadFieldComment } from './st-folder-hooks'
import { stFolderModule } from './st-folder-module'

type Props = {
  doc: STDocument
}
export function STFormationViewer({ doc }: Props) {
  const mime = parseMimeType(doc.mimeType)
  const structureId = toStr(mime?.parameters.structureId)

  const configResp = usePromise<FormConfig>(() => {
    return getFormConfig(structureId).then((f) => {
      return f!
    })
  }, [structureId])

  const highlightedPageIndexes = useMemo(() => {
    return doc.bookmarks.filter((b) => b.highlight).map((b) => b.pageIndex)
  }, [doc.bookmarks])

  const fieldComments = useProcessState(stFolderModule, (s) => {
    return s.folderState?.clientComments ?? []
  })

  const formConfig = configResp.status == 'fulfilled' ? configResp.value : undefined

  if (!formConfig) {
    return null
  }

  return (
    <FormationViewerInner
      formConfig={formConfig}
      highlightedPageIndexes={highlightedPageIndexes}
      fieldComments={fieldComments}
    />
  )
}

function FormationViewerInner({
  formConfig,
  highlightedPageIndexes,
  fieldComments
}: {
  formConfig: FormConfig
  highlightedPageIndexes: number[]
  fieldComments: FieldComment[]
}) {
  return (
    <ReadWriteProvider
      useRead={useRead}
      useFieldDecoration={useFieldDecoration}
      useWrite={useInputsWrite}
      useReadFieldComment={useReadFieldComment}
    >
      <PDFElementDocViewer mode="html">
        <FormationDocument
          config={formConfig!}
          highlightedPageIndexes={highlightedPageIndexes}
          fieldComments={fieldComments}
        />
      </PDFElementDocViewer>
    </ReadWriteProvider>
  )
}
