import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { BigTable } from '@st/react-util/big-table'
import { useProcessState } from '@st/redux'
import { FolderTag } from '@st/sdk'
import {
  ArchiveIcon,
  BellIcon,
  ContextMenu,
  ContextMenuItem,
  EyeIcon,
  LinkIcon,
  LockClosedIcon,
  LockOpenIcon,
  QuestionTipIcon,
  SendIcon,
  TagChip,
  TimeLabel,
  Tip,
  TrashIcon,
  UnarchiveIcon
} from '@st/theme'
import { SortDirection, SortState } from '@st/util/sort'
import clsx from 'clsx'
import { useMemo } from 'react'
import { FolderTagsList, FolderTagsListEditButton } from '../st-folder-viewer/folder-tags-list'
import { getStatusColor, getStatusLabel } from './common'
import {
  FOLDER_DEFAULT_SORT,
  FOLDER_SORT_TRANSITIONS,
  FolderListSortColumn
} from './st-folder-list-filters-module'
import { FolderRow, STFolderAction, STFolderListMessage } from './st-folder-list-module'
import { formatPercentage } from '@st/util/format'

type Props = {
  items: FolderRow[]
  selectedFolderIds: string[]
  unreadDocumentCountsByFolderId: Record<string, number>
  sortState: SortState<FolderListSortColumn> | undefined
  send: (message: STFolderListMessage) => void
  onAction: (action: STFolderAction) => void
  onToggleSort: (column: FolderListSortColumn) => void
}

export function STFolderListTable({
  items,
  selectedFolderIds,
  unreadDocumentCountsByFolderId,
  sortState,
  send,
  onAction,
  onToggleSort
}: Props) {
  const selectedIdsSet = useMemo(() => new Set(selectedFolderIds), [selectedFolderIds])
  const allFoldersSelected = selectedIdsSet.size > 0 && items.every((f) => selectedIdsSet.has(f.id))

  const folderTags = useProcessState(stOrganizationModule, (s) =>
    s.state ? s.state.folderTags : []
  )

  return (
    <BigTable
      columns={[
        { key: 'leftPadding', header: '', width: 64 },
        { key: 'select', header: '', width: 64 },
        { key: 'entities', header: 'Name' },
        { key: 'year', header: 'Tax Year', width: 90 },
        { key: 'type', header: 'Return Type', width: 90 },
        { key: 'questionnaire', header: 'Questionnaire', width: 300 },
        { key: 'checklist', header: 'Checklist', width: 80 },
        { key: 'documents', header: 'Documents', width: 80 },
        { key: 'createdAt', header: 'Created at', width: 160 },
        {
          key: 'questionnaireUpdatedAt',
          header: (
            <div className="flex flex-row items-center gap-1">
              Last activity{' '}
              <Tip
                title={`This column will be updated\nwhen a questionnaire is submitted\nor a new document is uploaded.`}
              >
                <QuestionTipIcon className="text-blue-500" />
              </Tip>
            </div>
          ),
          width: 160
        },
        { key: 'actions', header: '', width: 64 },
        { key: 'rightPadding', header: '', width: 64 }
      ]}
      rows={items}
      headerHeight={36}
      rowHeight={48}
      sortState={sortState!}
      sortOptions={{
        transitions: FOLDER_SORT_TRANSITIONS,
        defaultSort: FOLDER_DEFAULT_SORT
      }}
      renderHeaderCell={(column, { sortDirection, isSortable }) => {
        const isPaddingColumn = column.key == 'leftPadding' || column.key == 'rightPadding'

        if (column.key === 'select') {
          return (
            <div
              key={column.key}
              className="flex h-full flex-row items-center border-b border-gray-300 pl-6 pr-2"
            >
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={allFoldersSelected}
                onChange={() => send({ type: 'toggleSelectAll' })}
              />
            </div>
          )
        }

        return (
          <div
            key={column.key}
            className={clsx('flex h-full flex-row items-center text-xs uppercase text-gray-600', {
              'border-b border-gray-300': !isPaddingColumn
            })}
          >
            <div
              className={clsx({
                'cursor-pointer hover:text-gray-900': isSortable
              })}
              onClick={
                isSortable ? () => onToggleSort(column.key as FolderListSortColumn) : undefined
              }
            >
              {column.header}
            </div>
            {sortDirection && <SortArrow direction={sortDirection} />}
          </div>
        )
      }}
      renderRow={(folder, rowState, columns) => {
        const isSelected = selectedIdsSet.has(folder.id)

        const hoverClasses = {
          'group-hover:bg-stone-100': !isSelected,
          'bg-blue-100 group-hover:bg-blue-200': isSelected
        }

        return (
          <div
            key={folder.id}
            className={clsx('group h-full cursor-pointer', { 'opacity-60': folder.archived })}
            style={rowState.style}
            onClick={(e) => {
              if ((e.target as Element).closest('button, input')) {
                return
              }
              if (selectedIdsSet.size > 0) {
                send({ type: 'toggleFolderSelected', folderId: folder.id })
              } else {
                onAction({ type: 'openFolder', folder: folder })
              }
            }}
          >
            {columns.map((column) => {
              const isPaddingColumn = column.key == 'leftPadding' || column.key == 'rightPadding'
              return (
                <div
                  key={column.key}
                  className={clsx(
                    'h-full',
                    {
                      'border-b border-gray-200': !isPaddingColumn
                    },
                    isPaddingColumn ? '' : hoverClasses
                  )}
                >
                  {column.key === 'select' && (
                    <SelectCell isSelected={isSelected} folderId={folder.id} send={send} />
                  )}
                  {column.key === 'entities' && (
                    <EntitiesCell folder={folder} folderTags={folderTags} onAction={onAction} />
                  )}
                  {column.key === 'year' && (
                    <div className="flex h-full flex-row items-center text-base text-gray-500">
                      {folder.year}
                    </div>
                  )}
                  {column.key === 'type' && (
                    <div className="flex h-full flex-row items-center text-base text-gray-500">
                      {folder.type}
                    </div>
                  )}
                  {column.key === 'questionnaire' && (
                    <QuestionnaireCell folder={folder} onAction={onAction} />
                  )}
                  {column.key === 'checklist' && (
                    <ChecklistProgressCell
                      checklistItemsCompleteCount={folder.checklistItemsCompleteCount}
                      checklistItemsTotalCount={folder.checklistItemsTotalCount}
                    />
                  )}
                  {column.key === 'documents' && (
                    <DocumentsCell
                      folder={folder}
                      unreadCount={unreadDocumentCountsByFolderId[folder.id] || 0}
                    />
                  )}
                  {column.key === 'createdAt' && folder.createdAt && (
                    <TimeLabel
                      className="flex h-full flex-row items-center text-base text-gray-500"
                      dateTime={folder.createdAt}
                    />
                  )}
                  {column.key === 'questionnaireUpdatedAt' && folder.questionnaireUpdatedAt && (
                    <TimeLabel
                      className="flex h-full flex-row items-center text-base text-gray-500"
                      dateTime={folder.questionnaireUpdatedAt}
                    />
                  )}
                  {column.key === 'actions' && <ActionsCell folder={folder} onAction={onAction} />}
                </div>
              )
            })}
          </div>
        )
      }}
      renderEmptyMessage={() => (
        <div
          className="flex flex-row items-center"
          // actual left padding of the table (64px) + width of the checkbox column (64px) lines the text up with the name header
          style={{ height: 48, paddingLeft: 64 + 64, paddingRight: 64 }}
        >
          No workpapers found
        </div>
      )}
    />
  )
}

function SelectCell({
  isSelected,
  folderId,
  send
}: {
  isSelected: boolean
  folderId: string
  send: (message: STFolderListMessage) => void
}) {
  return (
    <div className="flex h-full flex-row items-center pl-6 pr-2">
      <input
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-blue-600"
        checked={isSelected}
        onChange={() => send({ type: 'toggleFolderSelected', folderId })}
      />
    </div>
  )
}

function EntitiesCell({
  folder,
  folderTags,
  onAction
}: {
  folder: FolderRow
  folderTags: FolderTag[]
  onAction: (action: STFolderAction) => void
}) {
  const entityName = formatFolderEntityName(folder.entities)
  return (
    <div className="flex h-full flex-row items-center gap-2">
      {entityName ? (
        <div className="overflow-hidden text-ellipsis text-nowrap text-base font-semibold text-gray-900">
          {entityName}
        </div>
      ) : (
        <div className="overflow-hidden text-ellipsis text-nowrap text-base italic text-gray-400">
          Missing name
        </div>
      )}
      {folder.tagIds && (
        <FolderTagsList
          leading={folder.archived && <TagChip color="gray">Archived</TagChip>}
          folderTags={folderTags}
          tagIds={folder.tagIds}
        />
      )}
      <Tip title="Edit tags">
        <FolderTagsListEditButton
          onClick={() => onAction({ type: 'editFolderTags', folder: folder })}
        />
      </Tip>
    </div>
  )
}

function QuestionnaireCell({
  folder,
  onAction
}: {
  folder: FolderRow
  onAction: (action: STFolderAction) => void
}) {
  return (
    <div className="flex h-full flex-row items-center gap-2">
      <TagChip color={getStatusColor(folder.questionnaireStatus)} className="w-28">
        {getStatusLabel(folder.questionnaireStatus) +
          (folder.questionnaireProgress && folder.questionnaireStatus == 'in_progress'
            ? ` ${formatPercentage(folder.questionnaireProgress)}%`
            : '')}
      </TagChip>

      {folder.questionnaireSent && <TagChip color="gray">Sent</TagChip>}

      {folder.questionnaireEnrolledInReminders && (
        <Tip title="Enrolled in reminders">
          <button
            className="rounded-sm bg-gray-200 p-0.5"
            onClick={() => onAction({ type: 'viewRemindersSchedule', folder: folder })}
          >
            <BellIcon className="h-3 w-3" />
          </button>
        </Tip>
      )}

      <div className="invisible mx-2 flex flex-row items-center gap-2 group-hover:visible">
        <Tip title="Preview questionnaire">
          <button
            className="size-6"
            onClick={() => onAction({ type: 'previewQuestionnaire', folderId: folder.id })}
          >
            <EyeIcon className="size-4" />
          </button>
        </Tip>
        <Tip title="Copy shareable link">
          <button
            className="size-6"
            onClick={() => onAction({ type: 'openCopyShareableLinkDialog', folder: folder })}
          >
            <LinkIcon className="size-4" />
          </button>
        </Tip>
        <Tip title="Send questionnaire">
          <button
            className="size-6"
            onClick={() => onAction({ type: 'openSendQuestionnaireDialog', folder: folder })}
          >
            <SendIcon className="size-4" />
          </button>
        </Tip>
      </div>
    </div>
  )
}

function ActionsCell({
  folder,
  onAction
}: {
  folder: FolderRow
  onAction: (action: STFolderAction) => void
}) {
  return (
    <div className="flex h-full flex-row items-center pr-2">
      <ContextMenu>
        {folder.archived == false && (
          <ContextMenuItem
            icon={<ArchiveIcon className="size-4" />}
            onClick={() => onAction({ type: 'archiveFolder', folder: folder })}
          >
            Archive workpaper
          </ContextMenuItem>
        )}

        {folder.archived == true && (
          <ContextMenuItem
            icon={<UnarchiveIcon className="size-4" />}
            onClick={() => onAction({ type: 'unarchiveFolder', folder: folder })}
          >
            Unarchive workpaper
          </ContextMenuItem>
        )}

        {folder.questionnaireStatus == 'submitted' ? (
          <ContextMenuItem
            icon={<LockOpenIcon className="size-4" />}
            onClick={() => onAction({ type: 'unlockQuestionnaire', folder: folder })}
          >
            Unlock client questionnaire
          </ContextMenuItem>
        ) : (
          <ContextMenuItem
            icon={<LockClosedIcon className="size-4" />}
            onClick={() => onAction({ type: 'submitQuestionnaire', folder: folder })}
          >
            Submit and lock client questionnaire
          </ContextMenuItem>
        )}

        <ContextMenuItem
          icon={<TrashIcon className="size-4" />}
          onClick={() => onAction({ type: 'deleteFolder', folder: folder })}
        >
          Delete workpaper
        </ContextMenuItem>
      </ContextMenu>
    </div>
  )
}

function ChecklistProgressCell({
  checklistItemsCompleteCount,
  checklistItemsTotalCount
}: {
  checklistItemsCompleteCount: number
  checklistItemsTotalCount: number
}) {
  return (
    <div className="flex h-full flex-row items-center text-base text-gray-500">
      {checklistItemsCompleteCount} / {checklistItemsTotalCount}
    </div>
  )
}

function DocumentsCell({ folder, unreadCount }: { folder: FolderRow; unreadCount: number }) {
  return (
    <div className="flex h-full flex-row items-center text-base text-gray-500">
      {folder.documentsCount}
      {unreadCount > 0 && <div className="ml-1 size-1.5 rounded-full bg-red-600" />}
    </div>
  )
}

function SortArrow({ direction }: { direction: SortDirection }) {
  return <span className="ml-1 text-xs text-gray-500">{direction == 'asc' ? '▲' : '▼'}</span>
}
