import { clientFolderShareableLink } from '@features/routing'
import type { SDKMessage, SDKResponse } from '@features/sdk-module'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { PlatformMessage } from '@features/st-pdf-viewer/platform-module'
import { defineModule, defineTask } from '@st/redux'
import { Entities, Folder, FolderSummary, FolderTag, STFolderListState, STSDK } from '@st/sdk'
import { RETURN_TYPES } from '@st/tax-folder'
import { setToggle } from '@st/util/array-set'
import { formatEntities } from '@st/util/entities'
import { isNotEmpty } from '@st/util/json-value'
import { search } from '@st/util/search'
import { direction, levels, rank, sort, SortDirection, SortState } from '@st/util/sort'
import { applyPatch } from 'fast-json-patch'
import {
  FolderListSortColumn,
  QuestionnareFilterOptionKey,
  STFolderListFilters,
  stFolderListFiltersModule,
  STFolderListFiltersState
} from './st-folder-list-filters-module'

type STFolderListModuleState = {
  status: 'loading' | 'loaded'
  selectedFolderIds: string[]
  organizationId: string

  folderListState: STFolderListState | undefined
  stateVsn: number
}

export type STFolderAction =
  | { type: 'openSendQuestionnaireDialog'; folder: FolderRow }
  | { type: 'openCopyShareableLinkDialog'; folder: FolderRow }
  | { type: 'openFolder'; folder: FolderRow }
  | { type: 'deleteFolder'; folder: FolderRow }
  | { type: 'archiveFolder'; folder: FolderRow }
  | { type: 'unarchiveFolder'; folder: FolderRow }
  | { type: 'unlockQuestionnaire'; folder: FolderRow }
  | { type: 'submitQuestionnaire'; folder: FolderRow }
  | { type: 'editFolderTags'; folder: FolderRow }
  | { type: 'viewRemindersSchedule'; folder: FolderRow }
  | { type: 'copyQuestionnaireLink'; folderId: string }
  | { type: 'previewQuestionnaire'; folderId: string }
  | { type: 'downloadQuestionnaireLinks'; folderIds: string[] }
  | BulkActionType

export type BulkActionType =
  | { type: 'sendQuestionnaires'; folderIds: string[] }
  | { type: 'enrollInReminders'; folderIds: string[] }
  | { type: 'unenrollFromReminders'; folderIds: string[] }
  | { type: 'deleteFolders'; folderIds: string[] }

export type FolderRow = Pick<
  Folder,
  | 'id'
  | 'entities'
  | 'type'
  | 'year'
  | 'questionnaireStatus'
  | 'questionnaireSent'
  | 'questionnaireEnrolledInReminders'
  | 'tagIds'
  | 'checklistItemsCompleteCount'
  | 'checklistItemsTotalCount'
  | 'questionnaireUpdatedAt'
  | 'questionnaireProgress'
  | 'createdAt'
  | 'documentsCount'
  | 'archived'
>

export type STFolderListMessage =
  | { type: 'load' }
  | { type: 'stateLoad'; vsn: number; state: STFolderListState }
  | { type: 'stateDiff'; fromVsn: number; toVsn: number; diff: any }
  | { type: 'copyQuestionnaireLink'; folderId: string }
  | { type: 'folderListUpdated'; folderId: string }
  | { type: 'toggleFolderSelected'; folderId: string }
  | { type: 'deselectAll' }
  | { type: 'toggleSelectAll' }
  | { type: 'questionnaireUnenrolledFromReminders'; folderId: string }
  | { type: 'toggleShowArchivedFolders' }
  | SDKResponse

type STFolderListSend = {
  sdk: SDKMessage
  platform: PlatformMessage
}

type STFolderListInit = {
  organizationId: string
}

type STFolderListDeps = {
  stOrganization: typeof stOrganizationModule
  stFolderListFilters: typeof stFolderListFiltersModule
}

export const stFolderListModule = defineModule<
  STFolderListModuleState,
  STFolderListMessage,
  STFolderListInit,
  STFolderListSend,
  STFolderListDeps
>({
  name: 'stFolderList',
  deps: { stOrganization: stOrganizationModule, stFolderListFilters: stFolderListFiltersModule },
  init: ({ organizationId }, {}, deps) => {
    return {
      status: 'loading',
      organizationId: organizationId,
      selectedFolderIds: [],
      folderListState: undefined,
      stateVsn: -1
    } satisfies STFolderListModuleState
  },
  handle: (state, message, _, deps) => {
    switch (message.type) {
      case 'stateLoad': {
        return { ...state, folderListState: message.state, status: 'loaded' }
      }
      case 'stateDiff':
        const nextFolderState: STFolderListState = applyPatch(
          state.folderListState,
          message.diff,
          false, // don't validate patch operation
          false // don't mutate document (redux pattern so we must return new data)
        ).newDocument as any

        return {
          ...state,
          folderListState: nextFolderState,
          stateVsn: message.toVsn,
          // if some folders got deleted or removed for some reason
          // we don't want them to be part of the selection anymore
          selectedFolderIds: state.selectedFolderIds.filter(
            (id) => nextFolderState.folders[id] != undefined
          )
        }
      case 'copyQuestionnaireLink':
        const link = clientFolderShareableLink({
          folderId: message.folderId,
          organizationSlug: state.folderListState!.organization.slug
        })
        return [
          state,
          {
            platform: [
              { type: 'copyToClipboard', text: link },
              { type: 'showSnackbar', message: 'Copied questionnaire link' }
            ]
          }
        ]
      case 'questionnaireUnenrolledFromReminders':
        const folder = state.folderListState?.folders[message.folderId]
        if (!folder) {
          return state
        }
        return [
          state,
          {
            platform: {
              type: 'showSnackbar',
              message: `Unenrolled ${formatEntities(folder!.entities, 'internal')} from reminders`
            }
          }
        ]
      case 'response':
        const op = message.operation
        if (op.type == 'folders/getFolderListState') {
          return {
            ...state,
            status: 'loaded',
            items: Object.values(op.response.folders),
            organization: op.response.organization,
            tags: op.response.tags,
            // Deselect folders that are no longer in the list
            selectedFolderIds: state.selectedFolderIds.filter(
              (id) => op.response.folders[id] != undefined
            ),
            unreadDocumentCountsByFolderId: op.response.unreadDocumentCountsByFolderId
          }
        }
      case 'folderListUpdated':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/getFolderListState', organizationId: state.organizationId }
            }
          }
        ]
      case 'toggleFolderSelected':
        return {
          ...state,
          selectedFolderIds: setToggle(state.selectedFolderIds, message.folderId)
        }
      case 'toggleSelectAll':
        var allFoldersSelected = selAllFoldersSelected(state, deps)
        return {
          ...state,
          selectedFolderIds: allFoldersSelected
            ? []
            : selFinalFolderList(state, deps).map((f) => f.id)
        }
      case 'deselectAll':
        return { ...state, selectedFolderIds: [] }
      default:
        return state
    }
  }
})

export function selAllFoldersSelected(
  state: STFolderListModuleState,
  deps: { stFolderListFilters: STFolderListFiltersState }
): boolean {
  const selectedIds = new Set(state.selectedFolderIds)
  const folders = Object.values(state.folderListState!.folders)
  return filterFolderList(
    folders,
    deps.stFolderListFilters.filters,
    state.folderListState!.tags
  ).every((f) => selectedIds.has(f.id))
}

export function selFinalFolderList(
  state: STFolderListModuleState,
  deps: { stFolderListFilters: STFolderListFiltersState }
): FolderRow[] {
  const folders = Object.values(state.folderListState!.folders)
  const filteredFolders = filterFolderList(
    folders,
    deps.stFolderListFilters.filters,
    state.folderListState!.tags
  )
  return sortFolderList(filteredFolders, deps.stFolderListFilters.sortState)
}

function folderSummarySearchIndex(item: Folder, tags: FolderTag[]): string {
  const els = [formatFolderEntityName(item.entities)]
  for (const membership of item.memberships) {
    els.push(membership.user.email)
  }

  els.push(item.year.toString())
  els.push(item.type)

  for (const tid of item.tagIds) {
    const tag = tags.find((t) => t.id == tid)
    if (tag?.label) {
      els.push(tag?.label)
    }
  }

  return els.join(' ')
}

function filterFolderList(
  items: Folder[],
  filter: STFolderListFilters,
  tags: FolderTag[]
): FolderRow[] {
  var filteredItems: Folder[] = items

  if (isNotEmpty(filter.keywords)) {
    filteredItems = search(
      filteredItems,
      (el) => {
        const searchIndex = folderSummarySearchIndex(el, tags)
        return searchIndex
      },
      filter.keywords
    )
  }

  if (filter.year) {
    filteredItems = filteredItems.filter((el) => el.year == filter.year)
  }
  if (filter.type) {
    filteredItems = filteredItems.filter((el) => el.type == filter.type)
  }

  if (filter.questionnaireStatus) {
    filteredItems = filteredItems.filter((el) =>
      filterByQuestionnaireStatus(el, filter.questionnaireStatus!)
    )
  }

  // If we are not showing archived folders, we want to filter them out
  if (filter.showArchivedFolders == false) {
    filteredItems = filteredItems.filter((el) => !el.archived)
  }

  return filteredItems
}

function sortFolderList(
  items: FolderRow[],
  sortState: SortState<FolderListSortColumn> | undefined
): FolderRow[] {
  sortState = sortState ?? { column: 'entities', direction: 'asc' }

  switch (sortState.column) {
    case 'entities':
      return sort(
        items,
        levels(
          (a, b) => compareEntities(sortState.direction, a.entities, b.entities),
          direction(sortState.direction, 'type')
        )
      )
    case 'createdAt':
      return sort(items, levels(direction(sortState.direction, 'createdAt')))
    case 'questionnaireUpdatedAt':
      return sort(
        items,
        levels(
          direction(sortState.direction, 'questionnaireUpdatedAt'),
          direction(sortState.direction, 'createdAt')
        )
      )
    case 'year':
      return sort(items, levels(direction(sortState.direction, 'year')))
    case 'type':
      return sort(items, rank('type', RETURN_TYPES, sortState.direction))
    case 'questionnaire':
      return sort(
        items,
        levels(
          rank(
            'questionnaireStatus',
            ['not_seen_yet', 'in_progress', 'submitted'],
            sortState.direction
          )
        )
      )
    case 'checklist':
      return sort(
        items,
        levels(
          direction(sortState.direction, (s) => {
            if (s.checklistItemsTotalCount == 0) return 0
            return s.checklistItemsCompleteCount / s.checklistItemsTotalCount
          }),
          direction(sortState.direction, 'checklistItemsTotalCount')
        )
      )
    case 'documents':
      return sort(
        items,
        levels(
          direction(sortState.direction, 'documentsCount'),
          direction(sortState.direction, 'questionnaireUpdatedAt')
        )
      )
  }
}

function compareEntities(direction: SortDirection, a: Entities, b: Entities): number {
  const value = functionGetCompareName(a).localeCompare(functionGetCompareName(b), 'en', {
    sensitivity: 'base'
  })
  return direction == 'asc' ? value : -value
}

function functionGetCompareName(entities: Entities): string {
  const name = formatFolderEntityName(entities)
  // push empty names to the bottom of the list
  return name == '' ? 'zzzzz' : name
}

function filterByQuestionnaireStatus(
  item: FolderSummary,
  status: QuestionnareFilterOptionKey
): boolean {
  switch (status) {
    case 'not_seen_yet':
      return item.questionnaireStatus == 'not_seen_yet'
    case 'not_seen_yet_not_sent':
      return item.questionnaireStatus == 'not_seen_yet' && !item.questionnaireSent
    case 'not_seen_yet_sent':
      return item.questionnaireStatus == 'not_seen_yet' && item.questionnaireSent
    case 'in_progress':
      return item.questionnaireStatus == 'in_progress'
    case 'submitted':
      return item.questionnaireStatus == 'submitted'
    default:
      return true
  }
}

export function selFoldersById(state: STFolderListModuleState, folderIds: string[]): Folder[] {
  return folderIds.map((id) => state.folderListState!.folders[id]).filter(isNotEmpty)
}

export function selAvailableBulkActions(state: STFolderListModuleState): BulkActionType[] {
  const folders = selFoldersById(state, state.selectedFolderIds)
  const actions: BulkActionType[] = []

  actions.push({ type: 'sendQuestionnaires', folderIds: state.selectedFolderIds })

  const unenrolledFolders = folders.filter((f) => !f.questionnaireEnrolledInReminders)
  const enrolledFolders = folders.filter((f) => f.questionnaireEnrolledInReminders)

  if (unenrolledFolders.length > 0) {
    actions.push({ type: 'enrollInReminders', folderIds: unenrolledFolders.map((f) => f.id) })
  }

  if (enrolledFolders.length > 0) {
    actions.push({ type: 'unenrollFromReminders', folderIds: enrolledFolders.map((f) => f.id) })
  }

  actions.push({ type: 'deleteFolders', folderIds: state.selectedFolderIds })

  return actions
}

export const unenrollFromReminders = defineTask(
  stFolderListModule,
  async ({ send }, folderId: string, { sdk }: { sdk: STSDK }) => {
    await sdk.send({
      type: 'folders/unenrollQuestionnaireFromReminders',
      folderId: folderId
    })
    send({ type: 'questionnaireUnenrolledFromReminders', folderId })
  }
)

export function selUsedFolderCount(state: STFolderListModuleState, year: number): number {
  const usage = state.folderListState?.folderUsageByYear ?? {}
  return usage[year] ?? 0
}
