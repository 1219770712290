import { SDKMessage } from '@features/sdk-module'
import { defineModule, defineTask } from '@st/redux'
import { BillingState, STSDK } from '@st/sdk'
import { match } from 'ts-pattern'

export type OrganizationBillingState =
  | {
      status: 'loading'
      organizationId: string
    }
  | { status: 'loaded'; organizationId: string; billingState: BillingState }

type OrganizationBillingMessage = { type: 'load' } | SDKMessage

export const organizationBillingModule = defineModule<
  OrganizationBillingState,
  OrganizationBillingMessage,
  { organizationId: string },
  { sdk: SDKMessage }
>({
  name: 'billing',
  init: ({ organizationId }) => {
    return [
      { status: 'loading', organizationId },
      {
        sdk: {
          type: 'request',
          request: { type: 'billing/getBillingState', organizationId }
        } as SDKMessage
      }
    ]
  },
  handle: (state, message) => {
    console.log('state', state)
    return match<
      { state: OrganizationBillingState; message: OrganizationBillingMessage },
      OrganizationBillingState
    >({ state, message })
      .with(
        { message: { type: 'response', operation: { type: 'billing/getBillingState' } } },
        ({ message }) => {
          return {
            status: 'loaded',
            organizationId: state.organizationId,
            billingState: message.operation.response
          }
        }
      )
      .otherwise(() => state)
  }
})

export const upgradePlan = defineTask(
  organizationBillingModule,
  async (
    { getState },
    { planId, continueUrl }: { planId: string; continueUrl: string },
    { sdk }: { sdk: STSDK }
  ) => {
    const state = getState()

    try {
      const result = await sdk.send({
        type: 'billing/generatePlanCheckoutLink',
        organizationId: state.organizationId,
        planId,
        continueUrl
      })

      if (result.checkoutSessionUrl) {
        window.location.href = result.checkoutSessionUrl
      }
    } catch (error) {
      console.error('Failed to generate checkout link:', error)
    }

    return getState()
  }
)

type SubscriptionStatus = 'subscribed' | 'unsubscribed' | 'loading'

export function selSubscriptionStatus(state: OrganizationBillingState): SubscriptionStatus {
  if (state.status == 'loaded') {
    return state.billingState.activeSubscription ? 'subscribed' : 'unsubscribed'
  }
  return 'loading'
}
