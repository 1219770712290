import { ReactNode } from 'react'
import { ChevronDownIcon } from './icons'

type DocumentSplitDialogProps = {
  title: string
  subtitle: string
  children: ReactNode
  buttons: ReactNode
}

export function DocumentSplitDialog({
  title,
  subtitle,
  buttons,
  children
}: DocumentSplitDialogProps) {
  return (
    <div className="flex w-96 flex-col gap-6 rounded-md bg-white p-6 sm:shadow-md">
      <h1 className="text-lg font-semibold">{title}</h1>

      <div className="text-base">{subtitle}</div>

      {children}

      <div className="flex justify-end gap-2">{buttons}</div>
    </div>
  )
}

export function DocumentSplitList({ children }: { children: ReactNode }) {
  return <div className="max-h-[400px] divide-y divide-stone-200 overflow-y-auto">{children}</div>
}

type DocumentSplitListItemProps = {
  title: string
  tag?: ReactNode
  description: string
  selected: boolean
  onClickJump: () => void
  onToggleSelected: () => void
}
export function DocumentSplitListItem({
  title,
  tag,
  description,
  selected,
  onClickJump,
  onToggleSelected
}: DocumentSplitListItemProps) {
  return (
    <div className="grid grid-cols-[20px_1fr_20px] gap-2 bg-stone-100 p-3">
      <input type="checkbox" className="m-0.5" checked={selected} onChange={onToggleSelected} />
      <div>
        <div className="flex items-center gap-2">
          <div className="text-base font-semibold text-gray-900">{title}</div>
          {tag}
        </div>

        <div className="text-sm text-gray-700">{description}</div>
      </div>

      <ChevronDownIcon className="size-6 cursor-pointer" onClick={onClickJump} />
    </div>
  )
}
