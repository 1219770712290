import { SDKOperation } from './sdk.types'

export type STSDK = SDK<SDKOperation>

type SDKOpts = {
  baseUrl: string
  getToken: () => Promise<string | undefined>
  getExtraHeaders?: () => Record<string, string>
  onVersionEvent?: (event: VersionEvent) => void
  onRequestSent?: (request: SDKReq) => void
}
export function createSTClientSDK(opts: SDKOpts): SDK<SDKOperation> {
  return new SDK<SDKOperation>(opts)
}

export type Operation<Req = any, Res = any> = {
  request: Req
  response: Res
}

type SDKReq = {
  type: string
  [key: string]: any
}

type RequestOpts = {
  delay?: number
  maxRetries?: number
}

class SDK<T extends Operation<SDKReq, any>> {
  private baseUrl: string
  private getToken: () => Promise<string | undefined>
  private onVersionEvent: (event: VersionEvent) => void
  private getExtraHeaders: () => Record<string, string>
  private onRequestSent: (request: SDKReq) => void
  private lastApiVersion?: string

  constructor({ baseUrl, getToken, getExtraHeaders, onVersionEvent, onRequestSent }: SDKOpts) {
    this.baseUrl = baseUrl
    this.getToken = getToken
    this.getExtraHeaders = getExtraHeaders ?? (() => ({}))
    this.onVersionEvent = onVersionEvent ?? (() => {})
    this.onRequestSent = onRequestSent ?? (() => {})
  }

  async fetch<K extends T['request']['type']>(
    sdkRequest: Extract<T['request'], { type: K }>
  ): Promise<Response> {
    const token = await this.getToken()

    const request = this.constructRequest(sdkRequest, token)

    const response = await fetchWithRetry(request)

    this.onRequestSent(sdkRequest)

    return response
  }

  async send<K extends T['request']['type']>(
    req: Extract<T['request'], { type: K }>,
    opts?: RequestOpts
  ): Promise<Extract<T, { request: { type: K } }>['response']> {
    const token = await this.getToken()

    const request = this.constructRequest(req, token)

    const response = await fetchWithRetry(request, opts)
    this.onRequestSent(req)

    const apiVersion = response.headers.get('x-api-version')

    // if they are the same nothing to do
    if (apiVersion && this.lastApiVersion !== apiVersion) {
      // initializing apiVersion - nothing was there before
      if (!this.lastApiVersion) {
        this.onVersionEvent({ type: 'versionInit', version: apiVersion })
        this.lastApiVersion = apiVersion
      } else {
        this.onVersionEvent({
          type: 'versionChange',
          prevVersion: this.lastApiVersion,
          nextVersion: apiVersion
        })
        this.lastApiVersion = apiVersion
      }
    }

    if (response.status != 200) {
      const responseContentType = response.headers.get('content-type')
      const responseBody =
        responseContentType == 'application/json' ? await response.json() : await response.text()

      const requestHeaders = Object.fromEntries(request.headers.entries())
      if (requestHeaders['authorization']) {
        requestHeaders['authorization'] = redactAuth(requestHeaders['authorization'])
      }

      const trace: RequestTrace = {
        request: {
          method: request.method,
          url: request.url,
          headers: requestHeaders,
          body: req
        },
        response: {
          status: response.status,
          headers: Object.fromEntries(response.headers.entries()),
          body: responseBody
        }
      }
      const redactedTrace = redactRequestTrace(trace, /auth|cookie|token|key|secret/i)
      throw new SDKRequestError(redactedTrace.request, redactedTrace.response)
    }

    if (opts?.delay) {
      await delay(opts.delay)
    }

    return response.json()
  }

  private constructRequest<K extends T['request']['type']>(
    request: Extract<T['request'], { type: K }>,
    token: string | undefined
  ): Request {
    const headers: HeadersInit = { 'Content-Type': 'application/json' }
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    const extraHeaders = this.getExtraHeaders()
    for (const key of Object.keys(extraHeaders)) {
      headers[key] = extraHeaders[key]
    }

    if (request['folderId']) {
      headers['x-folder-id'] = request['folderId']
    }

    if (request['organizationId']) {
      headers['x-organization-id'] = request['organizationId']
    }

    return new Request(this.baseUrl + '/' + request.type, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    })
  }
}

function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms))
}

type Version = string

type VersionEvent =
  | { type: 'versionInit'; version: Version }
  | { type: 'versionChange'; prevVersion: Version; nextVersion: Version }

export type RequestTrace = {
  request: {
    url: string
    method: string
    headers: Record<string, string>
    body: Record<string, any>
  }
  response: {
    status: number
    headers: Record<string, string>
    body: string | Record<string, any>
  }
}

export function redactRequestTrace(trace: RequestTrace, regex: RegExp): RequestTrace {
  function redactHeaders(headers: Record<string, string>): Record<string, string> {
    const redacted = { ...headers }
    for (const key in redacted) {
      if (regex.test(key)) {
        redacted[key] = redactAuth(redacted[key])
      }
    }
    return redacted
  }

  return {
    request: {
      method: trace.request.method,
      url: trace.request.url,
      headers: redactHeaders(trace.request.headers),
      body: trace.request.body
    },
    response: {
      status: trace.response.status,
      headers: redactHeaders(trace.response.headers),
      body: trace.response.body
    }
  }
}

export class SDKRequestError extends Error {
  constructor(
    public request: RequestTrace['request'],
    public response: RequestTrace['response']
  ) {
    super(`API request failed: ${request.url} with status ${response.status}`)
    this.name = 'SDKRequestError'
    Object.setPrototypeOf(this, SDKRequestError.prototype)
  }
}

export class SDKRequestNetworkError extends Error {
  public request: RequestTrace['request']
  public cause?: unknown

  constructor(request: RequestTrace['request'], { cause }: { cause?: unknown }) {
    const causeMessage = cause instanceof Error ? cause.message : 'unknown'
    super(`Network request failed for: ${request?.url} with cause: ${causeMessage}`)
    this.name = 'SDKRequestNetworkError'
    this.request = request
    this.cause = cause

    Object.setPrototypeOf(this, SDKRequestNetworkError.prototype)
  }
}

type RetryStrategy = (attempt: number) => number

/**
 * A an exponential backoff formula.
 * For example, if your base delay starts at 3000ms, then the delays will be:
 * 3000ms, 6000ms, 12000ms, 24000ms, 48000ms, 96000ms (doubling each time)
 *
 * @param baseDelayMs
 * @returns
 */
function exponentialBackoff(baseDelayMs: number): RetryStrategy {
  return (attempt) => baseDelayMs * 2 ** (attempt - 1)
}

type FetchWithRetriesOpts = {
  maxRetries?: number
  strategy?: RetryStrategy
}
async function fetchWithRetry(request: Request, opts?: FetchWithRetriesOpts): Promise<Response> {
  opts = opts ?? {}

  const maxAttempts = opts.maxRetries ?? 7
  const backoffDelay = opts.strategy ?? exponentialBackoff(1000)

  let requestBody: any = undefined
  if (!request.bodyUsed) {
    // request.clone() ensures we don't consume `inputRequest` itself.
    requestBody = await request
      .clone()
      .json()
      .catch(() => undefined)
  }

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const attemptRequest = new Request(request, {
        body: requestBody ? JSON.stringify(requestBody) : undefined
      })

      return await fetch(attemptRequest)
    } catch (error: unknown) {
      // In browsers, a failed fetch (network error, CORS block, etc.) often is a TypeError.
      // However, if the fetch was intentionally aborted, error.name is "AbortError".
      // We *don't* want to retry an abort, so rethrow.
      if ((error as any)?.name === 'AbortError') {
        throw error
      }

      // We only retry if it's a TypeError, meaning a network failure, and we haven't exceeded max retries
      if (attempt === maxAttempts) {
        const headers = Object.fromEntries(request.headers.entries())
        delete headers['authorization']

        const failedRequest: RequestTrace['request'] = {
          method: request.method,
          url: request.url,
          headers,
          body: requestBody
        }
        throw new SDKRequestNetworkError(failedRequest, { cause: error })
      }

      const delay = backoffDelay(attempt)

      console.warn(`Request failed. Retrying request ${request.url} in ${delay}ms`)

      await new Promise((resolve) => setTimeout(resolve, delay))
    }
  }

  // Unreachable code
  throw new Error('Unhandled retry logic in fetchWithRetries')
}

function redactAuth(token: string | undefined | null) {
  if (!token) return 'null'
  return token.slice(0, 8) + '...<REDACTED>...'
}
