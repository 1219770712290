import { defineModule } from '@st/redux'
import { SortState, SortTransitions, toggleSort } from '@st/util/sort'

// Types related to the filters
export type QuestionnareFilterOptionKey =
  | 'not_seen_yet'
  | 'not_seen_yet_not_sent'
  | 'not_seen_yet_sent'
  | 'in_progress'
  | 'submitted'

type QuestionnaireFilterOption = {
  label: string
  value: QuestionnareFilterOptionKey | undefined
}

export const QUESTIONNAIRE_STATUS_FILTER_OPTIONS: QuestionnaireFilterOption[] = [
  { label: 'Not seen yet', value: 'not_seen_yet' },
  { label: 'Not seen yet, Not sent', value: 'not_seen_yet_not_sent' },
  { label: 'Not seen yet, Sent', value: 'not_seen_yet_sent' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Submitted', value: 'submitted' }
]

export type STFolderListFiltersState = {
  filters: STFolderListFilters
  sortState: SortState<FolderListSortColumn> | undefined
}

export type STFolderListFilters = {
  keywords: string
  year?: number
  questionnaireStatus?: QuestionnareFilterOptionKey
  type?: string
  showArchivedFolders: boolean
}

// Messages handled by this filters module
export type STFolderListFiltersMessage =
  | { type: 'updateFilters'; filters: STFolderListFilters }
  | { type: 'toggleSort'; column: FolderListSortColumn }
  | { type: 'toggleShowArchivedFolders' }

export const stFolderListFiltersModule = defineModule<
  STFolderListFiltersState,
  STFolderListFiltersMessage
>({
  name: 'stFolderListFilters',
  init: () => {
    return { filters: { keywords: '', showArchivedFolders: false }, sortState: FOLDER_DEFAULT_SORT }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'updateFilters':
        return { ...state, filters: message.filters }
      case 'toggleSort':
        return {
          ...state,
          sortState:
            toggleSort(state.sortState, message.column, FOLDER_SORT_TRANSITIONS) ??
            FOLDER_DEFAULT_SORT
        }
      case 'toggleShowArchivedFolders':
        return {
          ...state,
          filters: { ...state.filters, showArchivedFolders: !state.filters.showArchivedFolders }
        }
      default:
        return state
    }
  }
})

export type FolderListSortColumn =
  | 'entities'
  | 'createdAt'
  | 'questionnaireUpdatedAt'
  | 'year'
  | 'questionnaire'
  | 'type'
  | 'checklist'
  | 'documents'

export const FOLDER_DEFAULT_SORT: SortState<FolderListSortColumn> = {
  column: 'createdAt',
  direction: 'desc'
}

export const FOLDER_SORT_TRANSITIONS: SortTransitions<FolderListSortColumn> = {
  entities: ['asc', 'desc'],
  createdAt: ['asc', 'desc'],
  questionnaireUpdatedAt: ['asc', 'desc'],
  year: ['desc', 'asc'],
  questionnaire: ['desc', 'asc'],
  type: ['asc', 'desc'],
  checklist: ['desc', 'asc'],
  documents: ['desc', 'asc']
}
