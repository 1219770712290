import { FormationData, renderFormationChecklistToPDF, renderFormationToPDF } from '@st/formation'
import { FieldComment } from '@st/pdf'
import { defineTask } from '@st/redux'
import {
  STDocument,
  STDocumentType,
  STFolderDownloadState,
  STOpenFolderState,
  STSDK
} from '@st/sdk'
import { getFormConfig } from '@st/tax-folder'
import { FormConfig } from '@st/ui-config'
import { sanitizeFilename } from '@st/util'
import { zip } from '@st/util/archive'
import { asyncSectionMap } from '@st/util/async'
import { isNotEmpty } from '@st/util/json-value'
import { matchesMimeType } from '@st/util/mime'
import { parseISO8601Time } from '@st/util/time'
import { Directory, XFile } from '@st/util/xfile'
import { download } from '@util/download'
import { selDocumentsByIds, stDownloadFolderModule } from './st-download-folder-module'
import { formatFolderEntityName, getBookmarkSections, stFolderModule } from './st-folder-module'

type DownloadDocumentContext = {
  folderId: string
  sdk: STSDK
  format: 'pdf' | 'source'
}
export async function stDownloadDocumentHandler(
  doc: STDocument,
  { folderId, format, sdk }: DownloadDocumentContext
) {
  const url = await stDownloadURLHandler(doc, { folderId, format, sdk })
  if (url) {
    download(url, doc.name)
  }
}

async function stDownloadURLHandler(
  doc: STDocument,
  { folderId, format, sdk }: DownloadDocumentContext
): Promise<string | undefined> {
  if (matchesMimeType(doc.mimeType, 'application/formation-checklist')) {
    const state = await sdk.send({ type: 'folders/getOpenFolderState', folderId: folderId })
    const blob = await renderFormationChecklistToPDF({
      entityName: formatFolderEntityName(state.folder.entities),
      folder: state.folder,
      checklistItems: state.checklistItems,
      documents: state.documents,
      documentTypes: state.documentTypes,
      categories: state.categories
    })
    return URL.createObjectURL(blob)
  } else if (matchesMimeType(doc.mimeType, 'application/formation')) {
    const state = await sdk.send({ type: 'folders/getOpenFolderState', folderId: folderId })
    const formConfig = await getFormConfig(state.schemaId)
    const blob = await renderFormationToPDF(buildFormationData(doc, formConfig!, state))
    return URL.createObjectURL(blob)
  } else if (doc.urls.pdf && format == 'pdf') {
    return doc.urls.pdf
  } else if (doc.urls.sourceAttachment && format == 'source') {
    return doc.urls.sourceAttachment
  }
}

function buildFormationData(
  doc: STDocument,
  formConfig: FormConfig,
  state: STOpenFolderState
): FormationData {
  return {
    fieldHints: Object.fromEntries(state.fieldHints.map((hint) => [hint.key, hint.text])),
    inputs: state.inputs,
    priorInputs: state.prevInputs,
    formConfig: formConfig,
    highlightedPageIndexes: doc.bookmarks.filter((b) => b.highlight).map((b) => b.pageIndex),
    fieldComments: state.clientComments.map((c) => {
      const author = state.users.find((u) => u.id == c.authorId)
      return {
        key: c.key,
        body: c.body,
        author: author?.name ?? author?.email,
        time: c.time,
        pageNumber: c.pageNumber
      } satisfies FieldComment
    })
  }
}

export type DownloadWorkpaperContext = {
  sdk: STSDK
}

export type DownloadSingleDocumentContext = {
  sdk: STSDK
}

export const downloadSourceDocument = defineTask(
  stFolderModule,
  async (folder, doc: STDocument, { sdk }: DownloadSingleDocumentContext) => {
    const folderId = folder.getState().folderState!.folderId
    const url = await stDownloadURLHandler(doc, {
      folderId,
      sdk,
      format: 'source'
    })

    if (url) {
      download(url, doc.name)
      await sdk.send({
        type: 'folders/markFolderDocumentsExported',
        folderId: folderId,
        documentIds: [doc.id],
        exportFormat: 'source'
      })
    }
  }
)

export const downloadWorkpaperFromBackend = defineTask(
  stDownloadFolderModule,
  async (downloadFolder, arg, { sdk }: DownloadWorkpaperContext) => {
    const state = downloadFolder.getState()

    downloadFolder.send({ type: 'downloadStarted', format: 'pdf' })

    const result = await sdk.send({
      type: 'folders/generateWorkpaper',
      folderId: state.folderId,
      documentIds: state.selectedDocumentIds
    })

    download(result.downloadUrl!, result.filename!)

    downloadFolder.send({ type: 'downloadCompleted', format: 'pdf', errors: result.errors })

    await sdk.send({
      type: 'folders/markFolderDocumentsExported',
      folderId: state.folderId,
      documentIds: result.includedDocumentIds,
      exportFormat: 'pdf'
    })
    downloadFolder.send({ type: 'refreshFolderDownloadState' })
  }
)

export const downloadZip = defineTask(
  stDownloadFolderModule,
  async (downloadFolder, documentIds: string[], { sdk }: DownloadWorkpaperContext) => {
    const state = downloadFolder.getState()

    downloadFolder.send({ type: 'downloadStarted', format: 'zip' })

    const dir = await generateZipDownloadDirectory(state.downloadState!, documentIds, sdk)

    const zipResult = await zip(dir)

    if (zipResult.ok) {
      download(zipResult.value, formatFolderEntityName(state.downloadState!.entities) + '.zip')
    }

    downloadFolder.send({ type: 'downloadCompleted', format: 'zip', errors: [] })

    await sdk.send({
      type: 'folders/markFolderDocumentsExported',
      folderId: state.folderId,
      documentIds: documentIds,
      exportFormat: 'zip'
    })
    downloadFolder.send({ type: 'refreshFolderDownloadState' })
  }
)

async function generateZipDownloadDirectory(
  downloadState: STFolderDownloadState,
  documentIds: string[],
  sdk: STSDK
): Promise<Directory> {
  const sections = getBookmarkSections({
    documents: selDocumentsByIds(downloadState, documentIds),
    documentTypes: downloadState.documentTypes
  })

  const filesSections = await asyncSectionMap<STDocumentType, STDocument, XFile>(
    sections,
    async (doc, docIndex, documentType) => {
      const url = await stDownloadURLHandler(doc, {
        folderId: downloadState.folderId,
        sdk: sdk,
        format: 'source'
      })
      const index = downloadState!.documentTypes.indexOf(documentType) + 1

      const baseFilename =
        sanitizeFilename(doc.name) + (documentType.id == 'stanfordtax' ? '.pdf' : '')

      const filename = [
        String(index).padStart(2, '0'), // prefix of document
        documentType.name, // document type name
        doc.uploadedAt ? formatDate(parseISO8601Time(doc.uploadedAt)) : null, // yyyy-mm-dd
        baseFilename
      ]
        .filter(isNotEmpty)
        .join('_')

      return {
        type: 'file',
        name: filename,
        uri: url!,
        lastModified: doc.uploadedAt
      } satisfies XFile
    }
  )

  const dir: Directory = {
    type: 'directory',
    name: formatFolderEntityName(downloadState.entities) + '.zip',
    entries: {}
  }
  for (const section of filesSections) {
    for (const file of section.items) {
      dir.entries[file.name] = file
    }
  }

  return dir
}

function formatDate(date: Date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
