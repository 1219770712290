const inflectionExceptions: { [name: string]: string } = {
  category: 'categories'
}

export function inflect(count: number, word: string): string {
  if (count == 1) return word
  return inflectionExceptions[word] || word + 's'
}

const IRREGULAR_TENSES: Record<string, [string, string, string]> = {
  // verb: [base, present participle, past participle]
  copy: ['copy', 'copying', 'copied']
  // Add more irregular verbs here
}

export function itOrThem(count: number) {
  return count == 1 ? 'it' : 'them'
}

export function andWords(words: string[]) {
  if (words.length == 0) {
    return ''
  } else if (words.length == 1) {
    return words[0]
  } else if (words.length == 2) {
    return `${words[0]} and ${words[1]}`
  }
  return words.slice(0, -1).join(', ') + ` and ${words[words.length - 1]}`
}

function caseBy(string: string, char: string): string {
  return string
    .replace(/[-_]/g, char)
    .replace(/^[A-Z]/g, (letter) => letter.toLowerCase()) // lower case first char
    .replace(/[A-Z]/g, (letter) => `${char}${letter.toLowerCase()}`) // add chars after all
}

export function snakeCase(string: string): string {
  return caseBy(string, '_')
}

export function dasherize(string: string): string {
  return caseBy(string, '-')
}

export function titleify(string: string): string {
  var index = 0
  return caseBy(string, ' ').replace(/\b[A-Za-z]/g, (letter) => {
    index++
    return index == 1 ? letter.toUpperCase() : letter.toLowerCase()
  })
}

export function camelize(str: string): string {
  return str
    .split(/[_-]/g)
    .map((part, idx) => (idx == 0 ? part : part.charAt(0).toUpperCase() + part.slice(1)))
    .join('')
}

export function transformKeys<V>(
  transform: (str: string) => string,
  object: Record<string, V>
): Record<string, V> {
  const result: any = {}
  const keys = Object.keys(object)
  for (var k of keys) {
    result[transform(k)] = (object as any)[k]
  }
  return result
}
