import { useIntercomChatVisible } from '@features/analytics/analytics-service'
import { stFolderListModule } from '@features/st-folder-list/st-folder-list-module'
import { STBulkSendEditQuestionnaireDialog } from '@features/st-folder-viewer/st-bulk-send-edit-questionnaire-dialog'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import { Folder } from '@st/sdk'
import {
  Banner,
  Button,
  CheckIcon,
  Dialog,
  DialogButtons,
  EyeIcon,
  Modal,
  PencilIcon,
  Tip,
  Wizard
} from '@st/theme'
import { inflect } from '@st/util/inflect'
import { clsx } from 'clsx'
import { useState } from 'react'
import { match } from 'ts-pattern'
import {
  selBulkSendSelectedFolders,
  stBulkSendQuestionnairesModule
} from './st-bulk-send-questionnaires-module'
import { STEditTemplateInlineWizardPage } from './st-edit-template-inline-wizard-page'
import { formatSalutation } from '@st/util/entities'

type SendAction =
  | {
      type: 'edit'
      folder: Folder
    }
  | { type: 'confirmSend' }
  | { type: 'confirmEnrollInReminders' }

export function STQuestionnairesBulkEmailPage({
  folderIds,
  onClose,
  onClickEnrollInReminders
}: {
  folderIds: string[]
  onClose: () => void
  onClickEnrollInReminders: (folderIds: string[]) => void
}) {
  useIntercomChatVisible(false)

  const organizationId = useProcessState(stFolderListModule, (s) => s.organizationId)

  const stBulkSendQuestionnaires = useProcess(stBulkSendQuestionnairesModule, {
    organizationId,
    folderIds: folderIds
  })

  const state = useProcessState(stBulkSendQuestionnairesModule, (s) => s)

  const { folders, sendable, missingEmails } = useProcessState(
    stBulkSendQuestionnairesModule,
    (s, deps) =>
      selBulkSendSelectedFolders(
        s.folderIds,
        Object.values(deps.stFolderList.folderListState!.folders)
      )
  )

  const [action, setAction] = useState<SendAction | undefined>(undefined)

  function onClickSend() {
    if (folders.length > 0 && missingEmails.length == 0) {
      stBulkSendQuestionnaires.send({ type: 'send' })
    } else {
      setAction({ type: 'confirmSend' })
    }
  }

  if (state.status == 'editTemplate') {
    return (
      <STEditTemplateInlineWizardPage
        title="Send questionnaire to clients"
        subtitle="Clients will receive the following email with a button to access their questionnaire. The email will come from StanfordTax but it will be branded with your firm name and logo."
        templateType="folder_invite"
        onClose={onClose}
        onClickBack={onClose}
        onClickContinue={() => stBulkSendQuestionnaires.send({ type: 'previewRecipients' })}
      />
    )
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button
            variant="default"
            onClick={() => stBulkSendQuestionnaires.send({ type: 'editTemplate' })}
          >
            Back
          </Button>
          <div className="flex flex-row items-center gap-6">
            {match(state.status)
              .with('previewRecipients', () => (
                <Button variant="primary" onClick={onClickSend}>
                  Send {sendable.length} {inflect(sendable.length, 'questionnaire')}
                </Button>
              ))
              .with('sending', () => (
                <Button variant="primary" disabled={true}>
                  Sending...
                </Button>
              ))
              .with('sent', () => {
                return (
                  <Button
                    variant="primary"
                    onClick={() => setAction({ type: 'confirmEnrollInReminders' })}
                    leadingIcon={<CheckIcon className="h-4 w-4" />}
                  >
                    Done
                  </Button>
                )
              })
              .run()}
          </div>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main
        variant="wide"
        title={state.status == 'sent' ? 'Sent questionnaires!' : 'Send questionnaires'}
        subtitle={
          state.status == 'sent'
            ? 'The questionnaires have successfully been emailed.'
            : `You're about to send questionnaires to ${sendable.length} ${inflect(
                sendable.length,
                'client'
              )}.`
        }
        banner={
          <Banner variant="warning" className="sticky top-0 w-full rounded-none">
            Please review the emails below, including the salutations, before sending them.
          </Banner>
        }
      >
        <STBulkSendTable
          folders={folders}
          onClickEdit={(folder) => setAction({ type: 'edit', folder })}
        />
      </Wizard.Main>
      {match(action)
        .with({ type: 'edit' }, (action) => {
          return (
            <Modal isOpen={true}>
              <STBulkSendEditQuestionnaireDialog
                folderId={action.folder.id}
                onClose={() => setAction(undefined)}
              />
            </Modal>
          )
        })
        .with({ type: 'confirmSend' }, () => {
          if (sendable.length == 0) {
            return (
              <Modal isOpen={true}>
                <Dialog
                  title="Missing emails"
                  subtitle="None of the selected clients have emails. In order to send the questionnaires, you must first add emails for those clients."
                  buttons={
                    <DialogButtons>
                      <Button variant="primary" onClick={() => setAction(undefined)}>
                        OK
                      </Button>
                    </DialogButtons>
                  }
                />
              </Modal>
            )
          }
          if (missingEmails.length > 0) {
            return (
              <Modal isOpen={true}>
                <Dialog
                  title="Missing emails"
                  subtitle={`An email is missing for ${missingEmails.length} ${inflect(
                    missingEmails.length,
                    'client'
                  )}. Would you like to send the ${inflect(
                    sendable.length,
                    'questionnaire'
                  )} to the other ${sendable.length} ${inflect(sendable.length, 'client')}?`}
                  buttons={
                    <DialogButtons>
                      <Button variant="default" onClick={() => setAction(undefined)}>
                        Go back
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          stBulkSendQuestionnaires.send({ type: 'send' })
                          setAction(undefined)
                        }}
                      >
                        Send to just {sendable.length}
                      </Button>
                    </DialogButtons>
                  }
                />
              </Modal>
            )
          }
        })
        .with({ type: 'confirmEnrollInReminders' }, () => {
          return (
            <Modal isOpen={true}>
              <Dialog
                title="Enroll clients in reminders?"
                buttons={
                  <DialogButtons>
                    <Button variant="default" onClick={onClose}>
                      No
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        const sentFolderIds = sendable.map((f) => f.id)
                        onClickEnrollInReminders(sentFolderIds)
                      }}
                    >
                      Yes
                    </Button>
                  </DialogButtons>
                }
              >
                Would you like to enroll these clients in automated reminders?
              </Dialog>
            </Modal>
          )
        })
        .otherwise(() => null)}
    </Wizard.Scaffold>
  )
}

function STBulkSendTable({
  folders,
  onClickEdit
}: {
  folders: Folder[]
  onClickEdit: (folder: Folder) => void
}) {
  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr className="h-9">
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            Client
          </th>
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            Salutation
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Email
          </th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600"></th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {folders.map((folder) => (
          <tr
            key={folder.id}
            className={clsx('group h-12', { 'bg-green-200': folder.questionnaireSent })}
          >
            <td className="whitespace-nowrap pl-8">
              <div className="flex flex-row items-center gap-2">
                <div className="text-base font-semibold text-gray-900">
                  {formatFolderEntityName(folder.entities)}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap pl-8">
              <div className="flex flex-row items-center gap-2">
                <div className="text-base font-semibold text-gray-900">
                  {formatSalutation(folder.entities)}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              <div className="flex flex-row items-center">
                {folder.memberships.map((m) => m.user.email).join(', ')}

                {folder.memberships.length > 0 ? (
                  <div className="invisible ml-2 flex items-center group-hover:visible">
                    <Tip title="Edit emails">
                      <button onClick={() => onClickEdit(folder)}>
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </Tip>
                  </div>
                ) : (
                  <Button variant="link" onClick={() => onClickEdit(folder)}>
                    Add email
                  </Button>
                )}
              </div>
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              <div className="flex flex-row items-center">
                {/* <TagChip color={getStatusColor(row.status)}>{titleify(row.status)}</TagChip> */}
              </div>
            </td>
            <td>
              <div>
                <button
                  className="flex flex-row items-center gap-2 text-sm text-gray-600"
                  onClick={() => onClickEdit(folder)}
                >
                  <EyeIcon className="h-4 w-4" />
                  Preview
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
