import {
  documentUploadsModule,
  formatUploadError,
  selFailedUploads,
  Upload
} from '@features/document-uploads'
import { WizardUIComponent } from '@features/formation-viewer/formation-wizard/wizard-ui-component'
import {
  formatChecklistItem,
  selFolderSaveStatus,
  selIncompleteChecklistItems,
  selMissingReasonOptionsListByConstraintId,
  selResolvedInputs,
  selUploadedDocuments,
  stFolderModule
} from '@features/st-folder-viewer/st-folder-module'
import { MultiSelect } from '@features/ui-components/multi-select'
import { useProcess, useProcessState } from '@st/redux'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Dialog,
  DialogButtons,
  Modal,
  PostItNote,
  QuestionnaireBanner,
  QuestionnaireFile,
  QuestionnaireFilesList,
  QuestionnaireFooter,
  QuestionnaireHeader,
  QuestionnairePageContainer,
  QuestionnaireProgressBar,
  QuestionnaireSectionTab
} from '@st/theme'
import { Progress } from '@st/util/progress'
import { SimpleHtmlMarkdown } from '@util/simple-html-markdown'
import { useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import {
  DocumentStackItem,
  selDocumentsChecklistTopOfStack,
  selQuestionnaireCurrentPage,
  selQuestionnaireNavState,
  selQuestionnairePersonalizedNote,
  selQuestionnaireProgress,
  selQuestionnaireShouldShowSubtitle,
  selQuestionnaireTabs,
  selQuestionnaireValidationErrorMessage,
  STQuestionnaireMessage,
  stQuestionnaireModule
} from './st-questionnaire-module'
import { STQuestionnaireTrailingHeader } from './st-questionnaire-trailing-header'
import { STReviewQuestionnairePage } from './st-review-questionnaire-page'

type QuestionnaireAction = { type: 'viewAttachments' }

export function STQuestionnairePage() {
  const stQuestionnaire = useProcess(stQuestionnaireModule)
  const currentPage = useProcessState(stQuestionnaireModule, selQuestionnaireCurrentPage)!
  const navState = useProcessState(stQuestionnaireModule, selQuestionnaireNavState)
  const progress = useProcessState(stQuestionnaireModule, selQuestionnaireProgress)
  const tabs = useProcessState(stQuestionnaireModule, selQuestionnaireTabs)
  const documentUploads = useProcess(documentUploadsModule)
  const failedUploads = useProcessState(documentUploadsModule, selFailedUploads)

  const personalizedNote = useProcessState(stQuestionnaireModule, selQuestionnairePersonalizedNote)

  const shouldShowSubtitle = useProcessState(
    stQuestionnaireModule,
    selQuestionnaireShouldShowSubtitle
  )
  const organization = useProcessState(stQuestionnaireModule, (s) => s.organization)

  const validationErrorMessage = useProcessState(
    stQuestionnaireModule,
    selQuestionnaireValidationErrorMessage
  )
  const validationResult = useProcessState(stQuestionnaireModule, (s) => s.validationResult)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const inputs = useProcessState(stFolderModule, selResolvedInputs)

  useEffect(() => {
    // we re-run the validation on every key-stroke to ensure that the validation
    // message stays up to date to avoid confusion
    if (!validationResult.ok) {
      stQuestionnaire.send({ type: 'rerunValidation' })
    }
  }, [inputs])

  const saveStatus = useProcessState(stFolderModule, selFolderSaveStatus)
  const documentsCount = useProcessState(stFolderModule, (s) => selUploadedDocuments(s).length)
  const documentStackTopItem = useProcessState(
    stQuestionnaireModule,
    selDocumentsChecklistTopOfStack
  )

  const incompleteChecklistItems = useProcessState(stFolderModule, selIncompleteChecklistItems)

  const missingReasonsById = useProcessState(stFolderModule, (s) =>
    selMissingReasonOptionsListByConstraintId(s.folderState!)
  )

  const questionnaireJumpLocations = useProcessState(
    stFolderModule,
    (s) => s.folderState!.questionnaireJumpLocations
  )

  const [action, setAction] = useState<QuestionnaireAction | undefined>()

  const actionDialog = match(action)
    .with({ type: 'viewAttachments' }, () => (
      <Modal isOpen={true}>
        <QuestionnaireUploadedDocumentsDialog onClose={() => setAction(undefined)} />
      </Modal>
    ))
    .otherwise(() => null)

  if (currentPage.title == "You're almost done!") {
    return (
      <STReviewQuestionnairePage
        organization={organization}
        folderId={folderId}
        tabs={tabs}
        navState={navState}
        progress={progress}
        incompleteChecklistItems={incompleteChecklistItems}
        missingReasonOptionsByConstraintId={missingReasonsById}
        questionnaireJumpLocations={questionnaireJumpLocations}
        saveStatus={saveStatus}
        documentsCount={documentsCount}
        dialog={actionDialog}
        send={stQuestionnaire.send}
        onClickViewAttachments={() => setAction({ type: 'viewAttachments' })}
      />
    )
  }

  return (
    <QuestionnairePageContainer
      banner={
        validationErrorMessage && (
          <QuestionnaireBanner variant="error">{validationErrorMessage}</QuestionnaireBanner>
        )
      }
      header={
        <QuestionnaireHeader
          leading={<div className="text-md hidden font-semibold md:block">{organization.name}</div>}
          trailing={
            <STQuestionnaireTrailingHeader
              saveStatus={saveStatus}
              documentsCount={documentsCount}
              onClickViewAttachments={() => setAction({ type: 'viewAttachments' })}
              onClose={() => stQuestionnaire.send({ type: 'returnToDashboard' })}
            />
          }
        >
          {tabs.map((tab) => {
            return (
              <QuestionnaireSectionTab
                key={tab.route.sectionId}
                icon={tab.route.sectionId}
                caption={tab.label}
                selected={tab.route.sectionId == navState.cur.sectionId}
                onClick={() => stQuestionnaire.send({ type: 'navigate', route: tab.route })}
              />
            )
          })}
        </QuestionnaireHeader>
      }
      stickyNote={
        personalizedNote && (
          <div className="lg:sticky lg:left-6 lg:top-6 lg:h-0 lg:w-72">
            <PostItNote className="w-full" signature={organization.name!}>
              {personalizedNote!.note}
            </PostItNote>
          </div>
        )
      }
      footer={
        <QuestionnaireFooter>
          <Button
            size="l"
            variant="primary"
            leadingIcon={<ArrowLeftIcon className="h-4 w-4" />}
            disabled={navState.prev == undefined}
            onClick={() => stQuestionnaire.send({ type: 'navigate', route: navState.prev! })}
          >
            Back
          </Button>
          <QuestionnaireProgressBar value={Progress.toNumber(progress)} />
          <Button
            size="l"
            variant="primary"
            trailingIcon={<ArrowRightIcon className="h-4 w-4" />}
            disabled={navState.next == undefined}
            onClick={() => stQuestionnaire.send({ type: 'navigateNext' })}
          >
            Next
          </Button>
        </QuestionnaireFooter>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-col gap-2 py-2">
          {currentPage.title ? (
            <h1 className="text-center text-3xl font-bold">{currentPage.title}</h1>
          ) : null}

          {currentPage.subtitle && shouldShowSubtitle ? (
            <p className="text-center">
              <SimpleHtmlMarkdown content={currentPage.subtitle} />
            </p>
          ) : null}
        </div>

        {currentPage.components.map((i, index) => (
          <WizardUIComponent key={index} config={i} validationResult={validationResult} />
        ))}
      </div>

      {actionDialog}

      {documentStackTopItem && (
        <Modal isOpen={true}>
          <QuestionnaireDocumentStackDialog
            stackItem={documentStackTopItem}
            send={stQuestionnaire.send}
          />
        </Modal>
      )}

      {failedUploads.length > 0 && (
        <Modal isOpen={true}>
          <UploadFailedDialog
            failedUpload={failedUploads[0]}
            onClose={() =>
              documentUploads.send({ type: 'clearUpload', uploadId: failedUploads[0].id })
            }
          />
        </Modal>
      )}
    </QuestionnairePageContainer>
  )
}

function UploadFailedDialog({
  failedUpload,
  onClose
}: {
  failedUpload: Upload
  onClose: () => void
}) {
  return (
    <Dialog
      title="Upload failed"
      subtitle={
        <div>
          <p>
            The file <span className="font-bold">{failedUpload.filename}</span> failed:{' '}
            {formatUploadError(failedUpload.error!)}.
          </p>
          <p>Please try to upload the file again.</p>
        </div>
      }
      buttons={
        <DialogButtons>
          <Button variant="primary" onClick={onClose}>
            OK
          </Button>
        </DialogButtons>
      }
    />
  )
}

function QuestionnaireDocumentStackDialog({
  stackItem,
  send
}: {
  stackItem: DocumentStackItem
  send: (action: STQuestionnaireMessage) => void
}) {
  const options = stackItem.checklistItemCandidates.map((c) => ({
    key: c.id,
    label: formatChecklistItem(c)
  }))

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])

  return (
    <Dialog
      title="Select relevant items"
      subtitle={`Please select all items that apply to ${stackItem.doc.name}`}
      buttons={
        <DialogButtons>
          <Button
            variant="primary"
            disabled={selectedItemIds.length == 0}
            onClick={() => {
              if (selectedItemIds.length == 1 && selectedItemIds[0] == 'none') {
                send({ type: 'removeFromChecklistStack', docId: stackItem.doc.id })
              } else {
                send({
                  type: 'completeChecklistItemsWithDocument',
                  docId: stackItem.doc.id,
                  checklistItemIds: selectedItemIds
                })
              }
            }}
          >
            Select
          </Button>
        </DialogButtons>
      }
    >
      <MultiSelect options={options} value={selectedItemIds} onChange={setSelectedItemIds} />
    </Dialog>
  )
}

function QuestionnaireUploadedDocumentsDialog({ onClose }: { onClose: () => void }) {
  const documents = useProcessState(stFolderModule, selUploadedDocuments)

  return (
    <Dialog
      title="Uploaded documents"
      subtitle={documents.length == 0 ? 'You have not uploaded any documents yet' : undefined}
      buttons={
        <DialogButtons>
          <Button variant="primary" onClick={onClose}>
            OK
          </Button>
        </DialogButtons>
      }
    >
      <QuestionnaireFilesList>
        {documents.map((doc) => {
          return (
            <QuestionnaireFile
              key={doc.id}
              onClickPreview={() => {
                window.open(doc.urls.sourceInline, '_blank')
              }}
            >
              {doc.originalName}
            </QuestionnaireFile>
          )
        })}
      </QuestionnaireFilesList>
    </Dialog>
  )
}
