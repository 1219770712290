/**
 * Trigger a download for a file or a given URL.
 * If a File is provided, it uses the File's name property as the filename.
 * @param urlOrFile The URL, Blob, Uint8Array, or File to download.
 * @param filename (Optional) The name to give the downloaded file.
 */
export function download(file: File): void
export function download(url: string | Blob | Uint8Array, filename: string): void
export function download(data: string | Blob | Uint8Array | File, filename?: string): void {
  if (data instanceof File) {
    const objectUrl = URL.createObjectURL(data)
    download(objectUrl, data.name)
  } else if (data instanceof Blob) {
    const objectUrl = URL.createObjectURL(data)
    download(objectUrl, filename!)
  } else if (data instanceof Uint8Array) {
    const blob = new Blob([data])
    const objectUrl = URL.createObjectURL(blob)
    download(objectUrl, filename!)
  } else {
    const a = document.createElement('a')
    a.href = data as string
    a.download = filename!
    a.click()
  }
}
