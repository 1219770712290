import { ReactNode } from 'react'
import { CloseIcon } from './icons'

type ActivityFeedItemProps = {
  title: string
  description: ReactNode
  time: string
}
export function ActivityFeedItem({ title, description, time }: ActivityFeedItemProps) {
  return (
    <div className="flex items-start p-4 hover:bg-gray-50">
      <div className="flex-1">
        <div className="flex justify-between">
          <p className="text-sm font-medium text-gray-800">{title}</p>
          <span className="text-xs text-gray-500">{time}</span>
        </div>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  )
}

type ActivityFeedListProps = {
  title?: string
  onClose?: () => void
  children: ReactNode
}
export function ActivityFeedList({
  title = 'Notifications',
  onClose = () => {},
  children
}: ActivityFeedListProps) {
  return (
    <div className="w-80 rounded-lg bg-white shadow-lg">
      <div className="flex items-center justify-between border-b border-gray-200 p-4">
        <h2 className="font-medium text-gray-700">{title}</h2>
        <button className="text-gray-500" onClick={onClose}>
          <CloseIcon className="size-4" />
        </button>
      </div>

      <div className="max-h-[600px] divide-y divide-gray-200 overflow-y-auto">{children}</div>
    </div>
  )
}
