import { AppAnalytics, useAnalytics } from '@features/analytics'
import { useProcess } from '@st/redux'
import { createSTClientSDK } from '@st/sdk'
import { uuidv7 } from '@st/util/uuidv7'
import { RemoteSocket } from '@util/remote-socket'
import { useEffect } from 'react'
import { AppDeps } from './app-deps-provider'
import { useAuthClient } from './app-hooks'
import { authModule } from './auth/auth-module'
import { STAuthClientAdapter } from './auth/st-auth-client-adapter'
import { S3StorageClient } from './file-storage/adapters/s3-storage-client'
import * as Sentry from '@sentry/browser'

export function appDepsCreate(): AppDeps {
  const authClient = new STAuthClientAdapter()
  const buildCommitSha = process.env.NEXT_PUBLIC_BUILD_COMMIT_SHA ?? 'dev'

  console.log(`Setting up app with build ${buildCommitSha}`)

  let lastDiffSent: Record<string, Date> = {}

  const sdk = createSTClientSDK({
    baseUrl: process.env.NEXT_PUBLIC_API_V2_ENDPOINT!,
    getToken: async (): Promise<string | undefined> => {
      return authClient.getToken()
    },
    onRequestSent: (request) => {
      if (request.type == 'folders/setFolderInputs' || 'folders/finalizeDocumentUpload') {
        const requestSentAt = new Date()
        setTimeout(() => {
          const now = new Date()
          const lastDiffSentAt = lastDiffSent[request.folderId] ?? new Date(0)
          const diffSentInWindow = requestSentAt < lastDiffSentAt && lastDiffSentAt < now

          if (!diffSentInWindow) {
            Sentry.captureMessage('Folder diff was not sent in expected time window', {
              level: 'error',
              extra: {
                folderId: request.folderId,
                request: request,
                now: now,
                lastDiffSentAt: lastDiffSentAt,
                diffSentInWindow: diffSentInWindow
              }
            })
          }
        }, 10 * 1000)
      }
    },
    getExtraHeaders: () => {
      return { 'x-request-id': uuidv7(), 'x-client-version': buildCommitSha }
    },
    onVersionEvent: (event) => {
      if (event.type == 'versionInit') {
        console.log('versionInit', event)
      } else if (event.type == 'versionChange') {
        console.log('versionChange', event)

        if (event.nextVersion != event.prevVersion) {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      }
    }
  })

  const socket = new RemoteSocket({
    endpoint: httpToWebSocket(process.env.NEXT_PUBLIC_API_V2_ENDPOINT!),
    onChannelMessageReceived: (channelName, eventName, payload) => {
      if (channelName.startsWith('folder') && eventName == 'state:diff') {
        const folderId = channelName.split(':')[1]
        console.log('channelMessageReceived', channelName, eventName, payload)
        lastDiffSent[folderId] = new Date()
      }
    },
    onSocketEvent: (event) => {
      switch (event.type) {
        case 'disconnect':
          Sentry.addBreadcrumb({ level: 'warning', message: 'Socket disconnected', data: event })

          // "Normal" disconnects, in our case, are not actually normal
          // For websocket close with code=1000, phoenix.js will not attempt to re-establish the connection
          // This is bad because a lot of our API depends on keeping browser state in sync with server state, which happens
          // over phoenix channels
          //
          // fly.io will send the 1012 close code when the server restarts:
          // elixirforum.com/t/liveview-in-production-reloads-page-when-a-new-release-is-pushed/44624/8
          if (event.code == 1000 && !event.userInitiated) {
            console.warn(
              'Unexpected status 1000 (normal, non-user-initiated) websocket disconnect',
              event
            )

            Sentry.captureMessage(
              'Unexpected status 1000 (normal, non-user-initiated) websocket disconnect',
              {
                level: 'error',
                extra: event
              }
            )
          }
          break
      }
    }
  })

  authClient.subscribeToAuthStatus((e) => {
    switch (e.type) {
      case 'loggedIn':
        const token = authClient.getToken()
        socket.connect({ token: token! })
        break
      case 'loggedOut':
        socket.disconnect()
        break
    }
  })

  const storageAdapter = new S3StorageClient(sdk)

  return {
    authClient,
    sdk,
    storageAdapter,
    analytics: AppAnalytics,
    socket: socket,
    [Symbol.dispose]: () => null
  }
}

function httpToWebSocket(httpUrl: string) {
  const url = new URL(httpUrl)
  if (url.protocol === 'http:') {
    url.protocol = 'ws:'
    url.pathname = '/socket'
  } else if (url.protocol === 'https:') {
    url.protocol = 'wss:'
    url.pathname = '/socket'
  }
  return url.toString()
}

export function AppDepsPlugins() {
  const auth = useProcess(authModule)
  const analytics = useAnalytics()
  const authClient = useAuthClient()

  useEffect(() => {
    return authClient.subscribeToAuthStatus((event) => {
      switch (event.type) {
        case 'loggedIn':
          auth.send({ type: 'userIsLoggedIn', user: event.user })
          analytics.trackUserIdentified(event.user, {
            getToken: () => Promise.resolve(authClient.getToken())
          })
          break
        case 'loggedOut':
          auth.send({ type: 'userIsLoggedOut' })
          analytics.trackUserAnonymous()
          break
      }
    })
  }, [authClient])

  return <></>
}
