import { FloatingPortal, offset, useFloating, useHover, useInteractions } from '@floating-ui/react'
import { clsx } from 'clsx'

import {
  Children,
  cloneElement,
  CSSProperties,
  forwardRef,
  ReactElement,
  ReactNode,
  useState
} from 'react'
import { QuestionTipIcon } from './icons'

export function Tip({
  title,
  children,
  active = true,
  placement = 'top',
  strategy = 'absolute'
}: {
  title?: string
  children: ReactNode
  active?: boolean
  placement?: 'top' | 'right' | 'bottom' | 'left'
  strategy?: 'absolute' | 'fixed'
}) {
  return active ? (
    <ActiveTip title={title} children={children} placement={placement} strategy={strategy} />
  ) : (
    <InactiveTip children={children} />
  )
}

const TooltipBubble = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode
    style?: CSSProperties
  }
>(({ children, style }, ref) => {
  return (
    <div
      ref={ref}
      style={style}
      className="z-50 whitespace-pre-line rounded bg-gray-700 px-2 py-1 text-left text-xs font-normal text-white"
    >
      {children}
    </div>
  )
})

function ActiveTip({
  title,
  children,
  placement,
  strategy
}: {
  title?: string
  children: ReactNode
  placement: 'top' | 'right' | 'bottom' | 'left'
  strategy: 'absolute' | 'fixed'
}) {
  const [popupVisible, setPopupVisible] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    strategy,
    placement,
    middleware: [offset({ crossAxis: 0, mainAxis: 6 })],
    open: popupVisible,
    onOpenChange: setPopupVisible
  })

  const hover = useHover(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  return (
    <>
      {cloneElement(Children.only(children) as ReactElement, {
        ref: refs.setReference,
        ...getReferenceProps()
      })}
      {popupVisible && title && (
        <FloatingPortal>
          <TooltipBubble ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {title}
          </TooltipBubble>
        </FloatingPortal>
      )}
    </>
  )
}

function InactiveTip({ children }: { children: ReactNode }) {
  return <>{children}</>
}

export function QuestionTip({
  title,
  body,
  typeStyle = 'light'
}: {
  title?: string
  body?: string
  typeStyle?: 'light' | 'dark'
}) {
  const [popupVisible, setPopupVisible] = useState(false)

  const { refs, floatingStyles, context, placement } = useFloating({
    placement: 'top',
    middleware: [offset({ crossAxis: 0, mainAxis: 24 })],
    open: popupVisible,
    onOpenChange: setPopupVisible
  })

  const hover = useHover(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  return (
    <>
      <button ref={refs.setReference} {...getReferenceProps()}>
        <QuestionTipIcon className={clsx(typeStyle == 'dark' ? 'text-white' : 'text-blue-500')} />
      </button>
      {popupVisible && (title || body) && (
        <Callout
          position={placement === 'top' ? 'above' : 'below'}
          ref={refs.setFloating}
          style={floatingStyles}
          title={title}
          {...getFloatingProps()}
        >
          {body}
        </Callout>
      )}
    </>
  )
}

type CalloutProps = {
  position?: 'above' | 'below'
  title?: string | null
  children?: ReactNode | null
  style?: CSSProperties
}

const Callout = forwardRef<HTMLDivElement, CalloutProps>(function Callout(
  { position = 'above', title, children, style },
  ref
) {
  return (
    <div
      ref={ref}
      style={style}
      className="relative flex min-w-[284px] max-w-[350px] flex-col gap-2.5 rounded-lg bg-white p-5 drop-shadow-lg"
    >
      <div
        className={`absolute left-1/2 lowercase ${
          position === 'above' ? 'bottom-0 translate-y-full' : 'top-0 -translate-y-full'
        } -translate-x-1/2 border-[15px] border-transparent ${
          position === 'above' ? 'border-t-white' : 'border-b-white'
        }`}
      />
      {title && <h3 className="text-base font-bold text-gray-900">{title}</h3>}
      {children && (
        <span className="whitespace-pre-line text-base font-normal text-gray-900">{children}</span>
      )}
    </div>
  )
})
