import { useAppDeps } from '@features/app-deps-provider'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { useProcess } from '@st/redux'
import { STDocument } from '@st/sdk'
import { Button, Dialog, DialogButtons, TextInput } from '@st/theme'
import { extname, rootname } from '@st/util'
import { useState } from 'react'

export function STRenameDocumentDialog({
  folderId,
  doc,
  onClose
}: {
  folderId: string
  doc: STDocument
  onClose: () => void
}) {
  const { sdk } = useAppDeps()

  const platform = useProcess(platformModule)
  const [isSaving, setSaving] = useState(false)

  const docRootName = rootname(doc.name)
  const [newDocumentName, setNewDocumentName] = useState(docRootName)

  return (
    <Dialog
      title="Rename document"
      subtitle={`Enter a new name for ${doc.name}`}
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={newDocumentName == docRootName || newDocumentName.length == 0}
            onClick={async () => {
              setSaving(true)

              const newDocNameWithExtension = newDocumentName + extname(doc.name)
              const result = await sdk.send({
                type: 'folders/renameDocument',
                folderId: folderId,
                documentId: doc.id,
                // re-add the extension back on
                documentName: newDocNameWithExtension
              })
              setSaving(false)

              platform.send({
                type: 'showSnackbar',
                message: `Renamed document ${doc.name} to ${result.newDocumentName}`
              })

              onClose?.()
            }}
          >
            {isSaving ? 'Renaming...' : 'Rename'}
          </Button>
        </DialogButtons>
      }
    >
      <TextInput
        className="w-full"
        autoFocus={true}
        value={newDocumentName}
        onChange={setNewDocumentName}
      />
    </Dialog>
  )
}
