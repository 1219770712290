type Props = {
  value: boolean
  onChange: (value: boolean) => void
}

export function ToggleSwitch({ value, onChange }: Props) {
  return (
    <button
      type="button"
      className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
      role="switch"
      aria-checked={value}
      onClick={() => onChange(!value)}
    >
      <span
        aria-hidden="true"
        className="pointer-events-none absolute size-full rounded-md bg-white"
      ></span>
      <span
        aria-hidden="true"
        className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out ${
          value ? 'bg-blue-600' : 'bg-gray-200'
        }`}
      ></span>
      <span
        aria-hidden="true"
        className={`pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out ${
          value ? 'translate-x-5' : 'translate-x-0'
        }`}
      ></span>
    </button>
  )
}
