import { useProcess, useProcessState } from '@st/redux'
import { DocumentGenerationError } from '@st/sdk'
import { Button, Dialog, DialogButtons, Modal, TagChip } from '@st/theme'
import { inflect } from '@st/util/inflect'
import { SortDirection, SortState } from '@st/util/sort'
import { formatISO8601DateTime } from '@st/util/time'
import { match } from 'ts-pattern'
import { downloadWorkpaperFromBackend, downloadZip } from './st-download-document-handler'
import {
  DocumentItem,
  DownloadListSortColumn,
  selDownloadableDocumentList,
  selDownloadAdditionalDocuments,
  STDownloadFolderMessage,
  stDownloadFolderModule,
  STDownloadFolderModuleState
} from './st-download-folder-module'
import { formatDocumentWarning } from './st-folder-module'

export function STDownloadFolderDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const stDownloadFolder = useProcess(stDownloadFolderModule, { folderId: folderId })
  const state = useProcessState(stDownloadFolderModule)

  return (
    <STDownloadFolderDialogView
      state={state}
      send={stDownloadFolder.send}
      onClose={onClose}
      onClickDownloadWorkpaper={() =>
        stDownloadFolder.send(downloadWorkpaperFromBackend(undefined))
      }
      onClickDownloadZip={() => stDownloadFolder.send(downloadZip(state.selectedDocumentIds))}
      onClickDownloadAdditionalDocuments={(additionalDocumentIds) =>
        stDownloadFolder.send(downloadZip(additionalDocumentIds))
      }
      onClickCloseDownloadAdditionalDocuments={() =>
        stDownloadFolder.send({ type: 'closeDownloadAdditionalDocuments' })
      }
    />
  )
}

export function STDownloadFolderDialogView({
  state,
  send,
  onClose,
  onClickDownloadZip,
  onClickDownloadWorkpaper,
  onClickDownloadAdditionalDocuments,
  onClickCloseDownloadAdditionalDocuments
}: {
  state: STDownloadFolderModuleState
  send: (message: STDownloadFolderMessage) => void
  onClose: () => void
  onClickDownloadZip: () => void
  onClickDownloadWorkpaper: () => void
  onClickDownloadAdditionalDocuments: (documentIds: string[]) => void
  onClickCloseDownloadAdditionalDocuments: () => void
}) {
  const items = selDownloadableDocumentList(state)

  const selectedDocumentIds = state.selectedDocumentIds
  const allSelected = state.selectedDocumentIds.length == state.downloadState?.documents.length
  const download = state.download

  return (
    <>
      <Dialog
        title="Download workpaper"
        className="sm:max-w-6xl"
        buttons={
          <DialogButtons>
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={download.status == 'generating'}
              onClick={onClickDownloadZip}
            >
              {download.status == 'generating' && download.format == 'zip'
                ? 'Generating zip'
                : 'Download zip'}
            </Button>
            <Button
              variant="primary"
              disabled={download.status == 'generating'}
              onClick={onClickDownloadWorkpaper}
            >
              {download.status == 'generating' && download.format == 'pdf'
                ? 'Generating workpaper'
                : 'Download workpaper'}
            </Button>
          </DialogButtons>
        }
      >
        <FileDownloadsTable
          sortState={state.sortState}
          items={items}
          selectedDocumentIds={selectedDocumentIds}
          allSelected={allSelected}
          errors={[]}
          send={send}
        />
      </Dialog>
      {download.status == 'downloadAdditionalDocuments' && (
        <Modal isOpen={true}>
          <Dialog
            title="Download remaining documents"
            subtitle={`${download.errors.length} ${inflect(
              download.errors.length,
              'document'
            )} could not be included in the workpaper. You can download a zip below.`}
            buttons={
              <DialogButtons>
                <Button variant="subtle" onClick={onClickCloseDownloadAdditionalDocuments}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  disabled={download.downloadingZip}
                  onClick={() =>
                    onClickDownloadAdditionalDocuments(download.errors.map((el) => el.documentId))
                  }
                >
                  {download.downloadingZip ? 'Downloading' : 'Download'} {download.errors.length}{' '}
                  remaining {inflect(download.errors.length, 'document')}
                </Button>
              </DialogButtons>
            }
          >
            <ul className="list-disc">
              {selDownloadAdditionalDocuments(state).map((item) => (
                <li key={item.document.id} className="text-base text-gray-900">
                  {item.document.name} - {formatError(item.error)}
                </li>
              ))}
            </ul>
          </Dialog>
        </Modal>
      )}
    </>
  )
}

function FileDownloadsTable({
  sortState,
  items,
  selectedDocumentIds,
  allSelected,
  errors,
  send
}: {
  sortState: SortState<DownloadListSortColumn> | undefined
  items: DocumentItem[]
  selectedDocumentIds: string[]
  allSelected: boolean
  errors: DocumentGenerationError[] | undefined
  send: (message: STDownloadFolderMessage) => void
}) {
  return (
    <table className="w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr>
          <th className="w-8">
            <input
              type="checkbox"
              className="h-3 w-3"
              checked={allSelected}
              onChange={() => send({ type: 'toggleSelectAll' })}
            />
          </th>
          <th
            className="cursor-pointer px-2 py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'name' })}
          >
            File name {sortState?.column == 'name' && <SortArrow direction={sortState.direction} />}
          </th>
          <th className="w-32 py-2 text-left text-xs font-normal uppercase text-gray-500">
            Category
          </th>
          <th
            className="w-52 cursor-pointer py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'uploadedAt' })}
          >
            Uploaded at
            {sortState?.column == 'uploadedAt' && <SortArrow direction={sortState.direction} />}
          </th>
          <th
            className="w-32 cursor-pointer py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'exportedAt' })}
          >
            Status{' '}
            {sortState?.column == 'exportedAt' && <SortArrow direction={sortState.direction} />}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 overflow-y-auto">
        {items.map((item) => {
          const error = errors?.find((e) => e.documentId == item.document.id)
          return (
            <tr key={item.document.id}>
              <td className="text-center">
                <input
                  type="checkbox"
                  className="h-3 w-3"
                  checked={selectedDocumentIds.includes(item.document.id)}
                  onChange={() => send({ type: 'toggleSelection', documentId: item.document.id })}
                />
              </td>
              <td className="text-nowrap px-2 py-2 text-base text-gray-900">
                <div className="flex grow flex-row overflow-hidden text-ellipsis text-nowrap">
                  {item.document.name}
                  {item.warnings.map((warning, index) => (
                    <div key={index} className="ml-4 text-sm text-orange-800">
                      {formatDocumentWarning(warning)}
                    </div>
                  ))}
                  {error && <div className="ml-4 text-red-500">{formatError(error)}</div>}
                </div>
              </td>
              <td className="py-2 text-base text-gray-700">{item.documentType.name}</td>
              <td className="py-2 text-base text-gray-700">
                {item.document.uploadedAt && formatISO8601DateTime(item.document.uploadedAt)}
              </td>
              <td>
                <div className="flex flex-row">
                  {item.document.exportedAt ? <TagChip color="gray">Downloaded</TagChip> : null}
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function SortArrow({ direction }: { direction: SortDirection }) {
  return <span className="ml-1 text-xs text-gray-500">{direction == 'asc' ? '▲' : '▼'}</span>
}

function formatError(error: DocumentGenerationError) {
  return match(error.kind)
    .with('password_protected', () => 'Password protected')
    .with('unsupported_mime_type', () => 'Unsupported file type')
    .with('unknown', () => 'Unknown error')
    .exhaustive()
}
