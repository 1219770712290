import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { DocumentGenerationError, STDocument, STDocumentType, STFolderDownloadState } from '@st/sdk'
import { rootname } from '@st/util'
import { setToggle } from '@st/util/array-set'
import { extname } from '@st/util/file'
import { isNotEmpty } from '@st/util/json-value'
import { direction, sort, SortState, SortTransitions, toggleSort } from '@st/util/sort'
import { match } from 'ts-pattern'
import { DocumentWarning, getBookmarkSections, selWarningsByDocumentId } from './st-folder-module'

type Status = 'loading' | 'loaded'

export type STDownloadFolderModuleState = {
  status: Status
  folderId: string

  downloadState: STFolderDownloadState | undefined
  selectedDocumentIds: string[]

  download: Download

  sortState: SortState<DownloadListSortColumn> | undefined
}

type DownloadFormat = 'zip' | 'pdf'

type Download =
  | {
      status: 'idle'
    }
  | {
      status: 'generating'
      format: DownloadFormat
    }
  | {
      status: 'downloadAdditionalDocuments'
      format: DownloadFormat
      errors: DocumentGenerationError[]
      downloadingZip: boolean
    }
  | { status: 'idle' }

export type STDownloadFolderMessage =
  | { type: 'downloadFolder' }
  | { type: 'toggleSelection'; documentId: string }
  | { type: 'toggleSelectAll' }
  | { type: 'downloadStarted'; format: 'zip' | 'pdf' }
  | { type: 'downloadFailed'; errors: DocumentGenerationError[] }
  | { type: 'downloadCompleted'; format: 'zip' | 'pdf'; errors: DocumentGenerationError[] }
  | { type: 'refreshFolderDownloadState' }
  | { type: 'toggleSort'; column: DownloadListSortColumn }
  | { type: 'closeDownloadAdditionalDocuments' }
  | SDKResponse

export const stDownloadFolderModule = defineModule<
  STDownloadFolderModuleState,
  STDownloadFolderMessage,
  {
    folderId: string
  },
  {
    sdk: SDKMessage // download
  }
>({
  name: 'stDownloadFolder',
  init: ({ folderId }) => {
    return [
      {
        status: 'loading',
        folderId: folderId,
        downloadState: undefined,
        selectedDocumentIds: [],
        download: { status: 'idle' },
        sortState: undefined,
        lastErrors: []
      },
      {
        sdk: {
          type: 'request',
          request: { type: 'folders/getFolderDownloadState', folderId: folderId }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'response':
        const op = message.operation
        switch (op.type) {
          case 'folders/getFolderDownloadState':
            const warnings = selWarningsByDocumentId(op.response.documents)
            return {
              ...state,
              status: match<Status, Status>(state.status)
                .with('loading', () => 'loaded')
                .otherwise(() => 'loaded'),
              downloadState: op.response,
              selectedDocumentIds:
                // we don't want to select all documents if we've already selected some (we don't overwrite the selection)
                state.selectedDocumentIds.length == 0
                  ? op.response.documents
                      .map((doc) => doc.id)
                      .filter((docId) => {
                        const warningsForDoc = warnings[docId] ?? []
                        const hasDuplicates = warningsForDoc.some((w) => w.type == 'duplicate')
                        return !hasDuplicates
                      })
                  : state.selectedDocumentIds
            }
          default:
            return state
        }
      case 'refreshFolderDownloadState':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/getFolderDownloadState', folderId: state.folderId }
            }
          }
        ]
      case 'toggleSelection':
        return {
          ...state,
          selectedDocumentIds: setToggle(state.selectedDocumentIds, message.documentId)
        }
      case 'toggleSelectAll':
        return {
          ...state,
          selectedDocumentIds: selAllDocumentsSelected(state)
            ? []
            : state.downloadState!.documents.map((d) => d.id)
        }
      case 'toggleSort':
        return {
          ...state,
          sortState: toggleSort(state.sortState, message.column, SORT_TRANSITIONS)
        }
      case 'downloadFolder':
        return state
      case 'downloadStarted':
        if (state.download.status == 'downloadAdditionalDocuments' && message.format == 'zip') {
          return {
            ...state,
            download: {
              status: 'downloadAdditionalDocuments',
              format: message.format,
              errors: state.download.errors,
              downloadingZip: true
            } as Download
          }
        }
        return { ...state, download: { status: 'generating', format: message.format } }
      case 'downloadCompleted':
        if (message.errors.length > 0) {
          return {
            ...state,
            download: {
              status: 'downloadAdditionalDocuments',
              format: message.format,
              errors: message.errors,
              downloadingZip: false
            }
          }
        } else {
          return { ...state, download: { status: 'idle' } }
        }
      case 'downloadFailed':
        return {
          ...state,
          download: { status: 'idle' },
          selectedDocumentIds: state.selectedDocumentIds.filter((id) =>
            message.errors.every((e) => e.documentId != id)
          )
        }
      case 'closeDownloadAdditionalDocuments':
        return {
          ...state,
          download: { status: 'idle' }
        }
      default:
        return state
    }
  }
})

export type DocumentItem = {
  document: STDocument
  documentType: STDocumentType
  warnings: DocumentWarning[]
}

export function selDownloadableDocumentList(state: STDownloadFolderModuleState): DocumentItem[] {
  const warningsByDocumentId = selWarningsByDocumentId(state.downloadState?.documents ?? [])

  // we start out with sorting that matches the left bookmark sidebar
  const sections = getBookmarkSections({
    documentTypes: state.downloadState?.documentTypes ?? [],
    documents: state.downloadState?.documents ?? []
  })

  const items = sections.flatMap((s) =>
    s.items.map((d) => ({
      document: d,
      documentType: s.heading,
      warnings: warningsByDocumentId[d.id] ?? []
    }))
  )

  switch (state.sortState?.column) {
    case 'name':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.name.toLowerCase())
      )
    case 'uploadedAt':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.uploadedAt ?? '')
      )
    case 'exportedAt':
      return sort(
        items,
        direction(state.sortState.direction!, (i) => i.document.exportedAt ?? '')
      )
    default:
      return items
  }
}

export function selDocumentsByIds(state: STFolderDownloadState, ids: string[]) {
  const docs = ensureUniqueNames(state.documents ?? [])

  return ids.map((id) => docs.find((doc) => doc.id == id)).filter(isNotEmpty)
}

export function selAllDocumentsSelected(state: STDownloadFolderModuleState) {
  return state.selectedDocumentIds.length == state.downloadState?.documents.length
}

type AdditionalDocument = {
  document: STDocument
  documentType: STDocumentType
  error: DocumentGenerationError
}

export function selDownloadAdditionalDocuments(
  state: STDownloadFolderModuleState
): AdditionalDocument[] {
  const download = state.download
  if (download.status != 'downloadAdditionalDocuments') {
    return []
  }

  return selDownloadableDocumentList(state)
    .map((d) => {
      const error = download.errors.find((e) => e.documentId == d.document.id)
      if (!error) {
        return undefined
      }
      return {
        document: d.document,
        documentType: d.documentType,
        error: error
      } as AdditionalDocument
    })
    .filter(isNotEmpty)
}

export type DownloadListSortColumn = 'name' | 'uploadedAt' | 'exportedAt'

const SORT_TRANSITIONS: SortTransitions<DownloadListSortColumn> = {
  name: ['asc', 'desc', undefined],
  uploadedAt: ['desc', 'asc', undefined],
  exportedAt: ['desc', 'asc', undefined]
}

/**
 * Given a list of files, returns a new array (does not mutate original).
 * If duplicate names are encountered, it appends `(1)`, `(2)`, etc.
 */
function ensureUniqueNames(files: STDocument[]): STDocument[] {
  const usedNames = new Set<string>()

  return files.map((file) => {
    let candidate = file.name
    let counter = 1

    // If a name is already taken, keep incrementing until it's unique
    while (usedNames.has(candidate)) {
      const e = extname(file.name)
      const base = rootname(file.name)
      candidate = `${base} (${counter})${e}`
      counter++
    }

    usedNames.add(candidate)

    // Return a new XFile object (not mutating the original)
    return { ...file, name: candidate }
  })
}
