import {
  Bookmark,
  Box,
  Column,
  Expand,
  FORM_BORDER_GREY,
  FieldComment,
  PDFFormDocument,
  PDFFormPage,
  PageHeading,
  CommentSection,
  SectionRow,
  Space,
  Text
} from '@st/pdf'
import {
  ComponentConfigType,
  FormCategoryConfig,
  FormConfig,
  FormPageConfig,
  FormRowConfig,
  FormSectionConfig
} from '@st/ui-config'
import { Fragment, useMemo } from 'react'
import { FormationComponent } from './formation-component'

/**
 * Height of a multiline text field for PDF documents
 */
const FORMATION_MULTILINE_TEXT_HEIGHT = 370

type Props = {
  config: FormConfig
  highlightedPageIndexes?: number[]
  fieldComments: FieldComment[]
}
export function FormationDocument({ config, highlightedPageIndexes = [], fieldComments }: Props) {
  const pages = config.pages.map((p, index) => {
    if (index == 0) {
      return { ...p, title: config.name }
    }
    return p
  })

  return (
    <PDFFormDocument zoom={1.5}>
      {pages.map((pageConfig, index) => (
        <FormationDocumentPage
          key={index}
          config={pageConfig}
          highlight={highlightedPageIndexes.includes(index)}
        />
      ))}
      <FormationDocumentCommentsPage fieldComments={fieldComments} />
    </PDFFormDocument>
  )
}

function FormationDocumentPage({
  config,
  highlight
}: {
  config: FormPageConfig
  highlight?: boolean
}) {
  const sections = config.sections

  return (
    <PDFFormPage width={650} height={1000}>
      {config.title ? (
        <PageHeading hasData={!config.title!.includes('Organizer') && highlight}>
          {config.title!}
        </PageHeading>
      ) : null}
      {config.title ? (
        <Bookmark
          path={config.id}
          title={resolveTitle(config.title, highlight)}
          fontWeight={highlight ? 'bold' : 'normal'}
        />
      ) : null}

      {config.title ? <Space h={20} /> : null}

      {sections.map((s, index) => (
        <Fragment key={index}>
          <FormationPageSection config={s} />
          <Space h={20} />
        </Fragment>
      ))}
    </PDFFormPage>
  )

  function resolveTitle(title: string, hasData: boolean | undefined) {
    return title.replace(/^.*Organizer.*$/, 'Basic Info') + (hasData ? '*' : '')
  }
}

function FormationPageSection({ config }: { config: FormSectionConfig }) {
  const rows = config.rows ?? []
  const categoryRows = categoryToRows(config.categories ?? [])

  return (
    <CommentSection title={config.title ?? undefined}>
      {categoryRows.map((row, index) => {
        return <OrganizerRow key={index} config={row} />
      })}
      {rows.map((row, index) => {
        return <OrganizerRow key={index} config={row} />
      })}
    </CommentSection>
  )
}

function categoryToRows(categories: FormCategoryConfig[]): FormRowConfig[] {
  const rows: FormRowConfig[] = []

  for (var c of categories) {
    if (c.headerRowComponents.length > 0) {
      rows.push({
        id: c.name,
        components: c.headerRowComponents
      })
    }
    if (c.rows) rows.push(...c.rows)
  }

  return rows
}

const MULTI_ROW_COMPONENTS_TYPES: ComponentConfigType[] = [
  'LabeledGridTableConfig',
  'GridTableConfig',
  'StandardTableConfig',
  'AddressInputConfig',
  'ShareholderInputConfig',
  'ChecklistConfig'
]

function OrganizerRow({ config }: { config: FormRowConfig }) {
  const components = config.components

  if (components.length == 1) {
    const component = components[0]
    if (MULTI_ROW_COMPONENTS_TYPES.includes(component.__typename!)) {
      return <FormationComponent config={component} />
    } else if (component.__typename == 'TextInputConfig' && component.isMultiline) {
      return (
        <Box
          width={Infinity}
          height={FORMATION_MULTILINE_TEXT_HEIGHT}
          borderWidth={1}
          borderColor={FORM_BORDER_GREY}
        >
          <FormationComponent config={component} />
        </Box>
      )
    }
  }

  return (
    <SectionRow
      bullet={toBullet(config.bulletNumber)}
      indent={config.indent ?? undefined}
      isZeroWidth={(index) => components[index]?.__typename == 'NoteWidgetConfig'}
    >
      {components.map((c, index) =>
        c.__typename == 'NoteWidgetConfig' ? (
          <FormationComponent key={index} config={c} />
        ) : (
          <Expand key={index}>
            <FormationComponent config={c} />
          </Expand>
        )
      )}
    </SectionRow>
  )

  function toBullet(n: number | undefined | null) {
    if (!n) return undefined
    return `${n}.`
  }
}

function FormationDocumentCommentsPage({ fieldComments }: { fieldComments: FieldComment[] }) {
  const sections = useMemo(() => groupIntoSections(fieldComments), [fieldComments])
  const hasData = fieldComments.length > 0
  return (
    <PDFFormPage width={650} height={1000}>
      <PageHeading hasData={hasData}>Client comments</PageHeading>
      <Bookmark
        path={'client-comments'}
        title={hasData ? 'Client comments *' : 'Client comments'}
        fontWeight={hasData ? 'bold' : 'normal'}
      />

      <Space h={20} />

      {sections.map((s, index) => (
        <Column key={index} mainAxisSize="min" gap={8}>
          <Text key={index} fontSize={10} fontWeight="bold">
            {`Page ${s.pageNumber}`}
          </Text>
          {s.comments.map((c, index) => (
            <Text key={index} fontSize={10}>
              {wrapText(c.body, 100)}
            </Text>
          ))}
          <Space h={12} />
        </Column>
      ))}
    </PDFFormPage>
  )
}

function wrapText(text: string, maxChars: number): string {
  const words = text.trim().split(/\s+/) // Split on whitespace
  const lines: string[] = []
  let currentLine = ''

  for (const word of words) {
    // If adding this word (plus a space if currentLine isn't empty)
    // exceeds maxChars, start a new line
    if (currentLine.length + (currentLine ? 1 : 0) + word.length > maxChars) {
      lines.push(currentLine)
      currentLine = word // Start a new line with this word
    } else {
      // Otherwise, append to the current line
      currentLine += (currentLine ? ' ' : '') + word
    }
  }

  if (currentLine) {
    lines.push(currentLine)
  }

  return lines.join('\n')
}

type CommentSection = {
  pageNumber: number
  comments: FieldComment[]
}
function groupIntoSections(fieldComments: FieldComment[]): CommentSection[] {
  const sectionsByPageNumber: Record<string, CommentSection> = {}

  for (const c of fieldComments) {
    sectionsByPageNumber[c.pageNumber!.toString()] ||= { pageNumber: c.pageNumber!, comments: [] }
    sectionsByPageNumber[c.pageNumber!.toString()].comments.push(c)
  }

  const sections = Object.values(sectionsByPageNumber).toSorted(
    (a, b) => a.pageNumber - b.pageNumber
  )

  return sections
}
