export function RowsIcon({ className }: { className?: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className={className}>
      <path
        d="M19.5 12.9375H4.5C4.1519 12.9375 3.81806 13.0758 3.57192 13.3219C3.32578 13.5681 3.1875 13.9019 3.1875 14.25V18C3.1875 18.3481 3.32578 18.6819 3.57192 18.9281C3.81806 19.1742 4.1519 19.3125 4.5 19.3125H19.5C19.8481 19.3125 20.1819 19.1742 20.4281 18.9281C20.6742 18.6819 20.8125 18.3481 20.8125 18V14.25C20.8125 13.9019 20.6742 13.5681 20.4281 13.3219C20.1819 13.0758 19.8481 12.9375 19.5 12.9375ZM19.6875 18C19.6875 18.0497 19.6677 18.0974 19.6326 18.1326C19.5974 18.1677 19.5497 18.1875 19.5 18.1875H4.5C4.45027 18.1875 4.40258 18.1677 4.36742 18.1326C4.33225 18.0974 4.3125 18.0497 4.3125 18V14.25C4.3125 14.2003 4.33225 14.1526 4.36742 14.1174C4.40258 14.0823 4.45027 14.0625 4.5 14.0625H19.5C19.5497 14.0625 19.5974 14.0823 19.6326 14.1174C19.6677 14.1526 19.6875 14.2003 19.6875 14.25V18ZM19.5 4.6875H4.5C4.1519 4.6875 3.81806 4.82578 3.57192 5.07192C3.32578 5.31806 3.1875 5.6519 3.1875 6V9.75C3.1875 9.92236 3.22145 10.093 3.28741 10.2523C3.35337 10.4115 3.45005 10.5562 3.57192 10.6781C3.6938 10.8 3.83849 10.8966 3.99773 10.9626C4.15697 11.0286 4.32764 11.0625 4.5 11.0625H19.5C19.6724 11.0625 19.843 11.0286 20.0023 10.9626C20.1615 10.8966 20.3062 10.8 20.4281 10.6781C20.55 10.5562 20.6466 10.4115 20.7126 10.2523C20.7786 10.093 20.8125 9.92236 20.8125 9.75V6C20.8125 5.6519 20.6742 5.31806 20.4281 5.07192C20.1819 4.82578 19.8481 4.6875 19.5 4.6875ZM19.6875 9.75C19.6875 9.79973 19.6677 9.84742 19.6326 9.88258C19.5974 9.91775 19.5497 9.9375 19.5 9.9375H4.5C4.45027 9.9375 4.40258 9.91775 4.36742 9.88258C4.33225 9.84742 4.3125 9.79973 4.3125 9.75V6C4.3125 5.95027 4.33225 5.90258 4.36742 5.86742C4.40258 5.83225 4.45027 5.8125 4.5 5.8125H19.5C19.5497 5.8125 19.5974 5.83225 19.6326 5.86742C19.6677 5.90258 19.6875 5.95027 19.6875 6V9.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
