import { useAppDeps } from '@features/app-deps-provider'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { CHECKLIST_ITEMS_PER_PAGE } from '@st/formation'
import { PDFElementDocViewer, PDFFormDocument, PDFFormPage } from '@st/pdf'
import { DragHandlersProps, DragState, useDraggable } from '@st/react-util/use-draggable'
import { useProcess, useProcessState } from '@st/redux'
import { STChecklistItem, STDocument, STMissingReason } from '@st/sdk'
import {
  Button,
  Checklist,
  ChecklistVisual,
  CTACard,
  Dialog,
  DialogButtons,
  LinkIcon,
  Modal,
  PlusIcon,
  Select,
  TextInput
} from '@st/theme'
import { PencilSimpleIcon, TrashIcon } from '@st/theme/src/icons/12x12'
import { paginate, PaginatedPage } from '@st/util/array'
import { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'
import { ManageChecklistItemCompletion } from './st-checklist-completion-dialog'
import {
  formatChecklistItem,
  formatFolderEntityName,
  selChecklistItems,
  selDocumentTypeOptions,
  selMissingReasonOptionsListByConstraintId,
  stFolderModule
} from './st-folder-module'
import { STRequestItemsDialog } from './st-request-items-dialog'

type ChecklistItemAction =
  | { type: 'manageCompletion'; checklistItem: STChecklistItem }
  | { type: 'delete'; checklistItem: STChecklistItem }
  | { type: 'edit'; checklistItem: STChecklistItem }
  | { type: 'selectDocument'; checklistItem: STChecklistItem; document: STDocument }
  | { type: 'requestItems' }
  | {
      type: 'move'
      checklistItem: STChecklistItem
      referenceChecklistItem: STChecklistItem
      relation: 'before' | 'after'
    }

type Props = {}
export function STFormationChecklistViewer({}: Props) {
  const stFolder = useProcess(stFolderModule)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const entityName = useProcessState(stFolderModule, (s) =>
    formatFolderEntityName(s.folderState!.folder.entities)
  )
  const year = useProcessState(stFolderModule, (s) => s.folderState!.folder.year)
  const type = useProcessState(stFolderModule, (s) => s.folderState!.folder.type)
  const checklistItemPages = useProcessState(stFolderModule, (s) =>
    paginate(selChecklistItems(s), CHECKLIST_ITEMS_PER_PAGE)
  )
  const missingReasonOptionsByConstraintId = useProcessState(stFolderModule, (s) =>
    selMissingReasonOptionsListByConstraintId(s.folderState!)
  )

  const documents = useProcessState(stFolderModule, (s) => s.folderState!.documents)

  const [action, setAction] = useState<ChecklistItemAction | undefined>()

  function onAction(action: ChecklistItemAction) {
    switch (action.type) {
      case 'requestItems':
      case 'manageCompletion':
      case 'delete':
        setAction(action)
        break
      case 'move':
        stFolder.send({
          type: 'request',
          request: {
            type: 'folders/moveChecklistItem',
            folderId: folderId,
            checklistItemId: action.checklistItem.id,
            relation: action.relation,
            referenceChecklistItemId: action.referenceChecklistItem.id
          }
        })
        break
      case 'selectDocument':
        stFolder.send({ type: 'docSelected', docId: action.document.id })
        break
    }
  }

  const { dragState, handlers } = useDraggable<STChecklistItem>({
    onDrop: (e) => {
      onAction({
        type: 'move',
        checklistItem: e.item,
        referenceChecklistItem: e.target,
        relation: e.relation
      })
    }
  })

  return (
    <>
      <PDFElementDocViewer mode="html">
        <PDFFormDocument>
          {checklistItemPages.map((page) => {
            return (
              <PDFFormPage
                key={page.number}
                width={1000}
                height={1410}
                padding={{ top: 60, bottom: 60, left: 80, right: 80 }}
              >
                <STFormationChecklistHTML
                  entityName={entityName}
                  year={year}
                  type={type}
                  documents={documents}
                  page={page}
                  missingReasonOptionsByConstraintId={missingReasonOptionsByConstraintId}
                  onChecklistItemAction={onAction}
                  dragHandlers={handlers}
                  dragState={dragState}
                />
              </PDFFormPage>
            )
          })}
        </PDFFormDocument>
      </PDFElementDocViewer>
      {match(action)
        .with({ type: 'requestItems' }, () => (
          <Modal isOpen={true}>
            <STRequestItemsDialog folderId={folderId} onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .with({ type: 'manageCompletion' }, ({ checklistItem }) => (
          <Modal isOpen={true}>
            <ManageChecklistItemCompletion
              folderId={folderId}
              checklistItem={checklistItem}
              onClose={() => setAction(undefined)}
            />
          </Modal>
        ))
        .with({ type: 'delete' }, ({ checklistItem }) => (
          <Modal isOpen={true}>
            <ConfirmDeleteChecklistItemDialog
              folderId={folderId}
              checklistItem={checklistItem}
              onClose={() => setAction(undefined)}
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </>
  )
}

type ChecklistState =
  | { status: 'idle' }
  | { status: 'addItem' }
  | { status: 'editItem'; checklistItemId: string }

function STFormationChecklistHTML({
  entityName,
  page,
  documents,
  missingReasonOptionsByConstraintId,
  year,
  type,
  onChecklistItemAction,
  dragHandlers,
  dragState
}: {
  entityName: string
  type: string
  year: number
  page: PaginatedPage<STChecklistItem>
  documents: STDocument[]
  missingReasonOptionsByConstraintId: Record<string, STMissingReason[]>
  onChecklistItemAction: (action: ChecklistItemAction) => void
  dragHandlers: (item: STChecklistItem) => DragHandlersProps
  dragState: DragState<STChecklistItem>
}) {
  const [state, setState] = useState<ChecklistState>({ status: 'idle' })

  return (
    <div className="flex flex-col items-stretch">
      {page.isFirst && (
        <div className="flex flex-row items-center">
          <h1 className="mb-8 w-2/3 text-3xl">{`${entityName}'s Checklist`}</h1>

          <div className="flex w-1/3 flex-col items-end">
            <div className="flex flex-row justify-end gap-2">
              <div className="w-40 text-right text-xs uppercase text-gray-600">Return type</div>
              <div className="w-8 text-xs uppercase text-gray-900">{type}</div>
            </div>

            <div className="flex flex-row justify-end gap-2">
              <div className="w-40 text-right text-xs uppercase text-gray-600">Tax year</div>
              <div className="w-8 text-xs uppercase text-gray-900">{year}</div>
            </div>

            <Button
              variant="default"
              className="mt-2"
              onClick={() => onChecklistItemAction({ type: 'requestItems' })}
            >
              Request documents
            </Button>
          </div>
        </div>
      )}

      {page.items.length == 0 ? (
        <ChecklistItemEmptyStateCTA entityName={entityName} />
      ) : (
        <Checklist.Table>
          {page.items.map((item) => {
            const referencedDocument = item.reference?.documentId
              ? documents.find((doc) => doc.id == item.reference?.documentId)
              : undefined

            const missingReasonOptions =
              missingReasonOptionsByConstraintId[item.constraint.id] ?? []

            const incompleteReason = item.incompleteReason
              ? missingReasonOptions.find((r) => r.key == item.incompleteReason)
              : undefined

            if (state.status == 'editItem' && state.checklistItemId == item.id) {
              return (
                <EditChecklistItem
                  key={item.id}
                  checklistItem={item}
                  missingReasonOptions={missingReasonOptions}
                  onClose={() => setState({ status: 'idle' })}
                />
              )
            }

            return (
              <STFolderChecklistItem
                draggable={true}
                key={item.id}
                item={item}
                document={referencedDocument}
                incompleteReason={incompleteReason}
                onChecklistItemAction={(action) => {
                  switch (action.type) {
                    case 'edit':
                      setState({ status: 'editItem', checklistItemId: item.id })
                      break
                    default:
                      onChecklistItemAction(action)
                  }
                }}
                {...dragHandlers(item)}
                dragState={dragState}
              />
            )
          })}

          {page.isLast && (
            <Checklist.Footer>
              {/* Row should get rebuilt when items are added/removed */}
              <AddChecklistItemRow
                key={page.items.length}
                isActive={state.status == 'addItem'}
                onSetActive={(active) =>
                  setState(active ? { status: 'addItem' } : { status: 'idle' })
                }
              />
            </Checklist.Footer>
          )}
        </Checklist.Table>
      )}
    </div>
  )
}

function ChecklistItemEmptyStateCTA({ entityName }: { entityName: string }) {
  const [isActive, setActive] = useState(false)

  return (
    <div className="flex flex-col">
      {!isActive && (
        <CTACard
          icon={<ChecklistVisual className="h-16 w-16" />}
          message={`Create a checklist item for ${entityName}`}
          button={
            <Button className="mt-4" variant="primary" onClick={() => setActive(true)}>
              Add checklist item
            </Button>
          }
        />
      )}
      {isActive && <AddChecklistItemRow isActive={isActive} onSetActive={setActive} />}
    </div>
  )
}

function AddChecklistItemRow({
  isActive,
  onSetActive
}: {
  isActive: boolean
  onSetActive: (active: boolean) => void
}) {
  const { sdk } = useAppDeps()
  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const documentTypes = useProcessState(stFolderModule, selDocumentTypeOptions)

  const [documentTypeId, setDocumentTypeId] = useState<string | undefined>(undefined)
  const [note, setNote] = useState<string>('')
  const [isCreating, setCreating] = useState(false)

  if (!isActive) {
    // Show a 6-col row with just the “Add item” button in column #2
    return (
      <div className="group grid h-11 grid-cols-[2rem_2rem_13rem_16rem_12rem_5rem]">
        {/* column 1: (drag handle)—unused for “add” */}
        <div></div>

        <div className="flex items-center justify-center">
          <PlusIcon className="h-4 w-4 text-gray-900" />
        </div>

        {/* column 2: the “Add item” button */}
        <div className="flex items-center px-1">
          <Button variant="subtle" onClick={() => onSetActive(true)}>
            Add item
          </Button>
        </div>

        {/* columns 3–6 empty */}
        <div />
        <div />
        <div />
        <div />
      </div>
    )
  }

  // If isActive => show the “Add” form in the row
  return (
    <div className="group grid h-11 grid-cols-[2rem_2rem_13rem_16rem_12rem_5rem] bg-stone-100">
      {/* column 1: no drag handle here */}
      <div></div>

      {/* column 2: could show a plus icon or leave it empty */}
      <div className="flex items-center justify-center px-2">
        <PlusIcon className="h-4 w-4 text-gray-900" />
      </div>

      {/* column 3: the “MainCell” (document-type dropdown) */}
      <div className="flex items-center px-2">
        <Select
          className="w-full"
          placeholder="Document type"
          options={documentTypes}
          buildValue={(dt) => dt.id}
          buildLabel={(dt) => dt.name}
          value={documentTypeId}
          onChange={setDocumentTypeId}
        />
      </div>

      {/* column 4: the “Note” area */}
      <div className="flex items-center px-2">
        <TextInput placeholder="Note" className="w-full" value={note} onChange={setNote} />
      </div>

      {/* column 5: (the “Link” cell) — not used => empty */}
      <div></div>

      {/* column 6: actions */}
      <div className="flex items-center justify-start">
        <Button
          variant="primary"
          disabled={!documentTypeId && !isCreating}
          onClick={async () => {
            if (!documentTypeId) return
            setCreating(true)
            await sdk.send({
              type: 'folders/createChecklistItem',
              documentTypeId,
              name: documentTypes.find((dt) => dt.id === documentTypeId)?.name ?? '',
              note,
              folderId
            })
            onSetActive(false)
          }}
        >
          {isCreating ? 'Saving' : 'Save'}
        </Button>
        <Button variant="subtle" onClick={() => onSetActive(false)}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

function EditChecklistItem({
  checklistItem,
  missingReasonOptions,
  onClose
}: {
  missingReasonOptions: STMissingReason[]
  checklistItem: STChecklistItem
  onClose: () => void
}) {
  const { sdk } = useAppDeps()
  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const platform = useProcess(platformModule)
  const [editedChecklistItem, setEditedChecklistItem] = useState(checklistItem)
  const [isSaving, setIsSaving] = useState(false)

  const isModified =
    editedChecklistItem.note != checklistItem.note ||
    editedChecklistItem.incompleteReason != checklistItem.incompleteReason

  return (
    // 1) Match the row's grid classes and remove `flex`
    <div className="group grid h-11 grid-cols-[2rem_2rem_13rem_16rem_12rem_5rem] bg-stone-100">
      {/* Column 1 (drag handle)—empty in “edit mode”? */}
      <div></div>

      {/* Column 2 (checkbox)—could also be empty in “edit mode” */}
      <div></div>

      {/* Column 3 (the “MainCell” area) */}
      <div className="flex items-center pr-2">
        <Select
          className="w-full"
          placeholder={checklistItem.name}
          options={[]}
          buildValue={() => checklistItem.name}
          buildLabel={() => checklistItem.name}
          value={undefined}
        />
      </div>

      {/* Column 4 (the “Note” area) */}
      <div className="flex items-center pr-2">
        <TextInput
          placeholder="Note"
          className="w-full"
          autoFocus={true}
          value={editedChecklistItem.note}
          onChange={(value) => setEditedChecklistItem({ ...editedChecklistItem, note: value })}
        />
      </div>

      {/* Column 5 (the “Link” cell) */}
      <div className="flex items-center pr-2">
        <Select
          className="w-full text-sm"
          placeholder="Not uploaded"
          options={missingReasonOptions}
          buildValue={(r) => r.key}
          buildLabel={(r) => r.name}
          value={editedChecklistItem.incompleteReason}
          onChange={(value) =>
            setEditedChecklistItem({ ...editedChecklistItem, incompleteReason: value })
          }
        />
      </div>

      {/* Column 6 (the “actions” cell) */}
      <div className="flex items-center">
        <Button
          variant="primary"
          disabled={!isModified}
          onClick={async () => {
            setIsSaving(true)

            if (checklistItem.note != editedChecklistItem.note) {
              await sdk.send({
                type: 'folders/editChecklistItem',
                folderId,
                checklistItemId: checklistItem.id,
                note: editedChecklistItem.note
              })
            }

            if (checklistItem.incompleteReason != editedChecklistItem.incompleteReason) {
              await sdk.send({
                type: 'folders/setChecklistItemIncompleteReasons',
                folderId,
                options: [
                  {
                    checklistItemId: checklistItem.id,
                    incompleteReason: editedChecklistItem.incompleteReason ?? ''
                  }
                ]
              })
            }

            platform.send({
              type: 'showSnackbar',
              message: `Saved checklist item ${checklistItem.name}`
            })

            setIsSaving(false)
            onClose()
          }}
        >
          {isSaving ? 'Saving' : 'Save'}
        </Button>
        <Button variant="subtle" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

type STFolderChecklistItemProps = {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
  onChecklistItemAction: (action: ChecklistItemAction) => void
  dragState: DragState<STChecklistItem>
} & Partial<DragHandlersProps>

function STFolderChecklistItem({
  item,
  document,
  incompleteReason,
  onChecklistItemAction,
  dragState,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  onDragLeave
}: STFolderChecklistItemProps) {
  const dragStatus = (() => {
    if (dragState.status == 'hovering' && dragState.target == item) {
      return dragState.relation
    } else if (
      (dragState.status == 'hovering' || dragState.status == 'moving') &&
      dragState.item == item
    ) {
      return 'dragging'
    }
    return undefined
  })()

  return (
    <Checklist.Row
      key={item.id}
      checked={item.status == 'complete'}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      dragStatus={dragStatus}
    >
      <Checklist.DragHandleCell enabled={draggable} />
      <Checklist.CheckboxCell
        checked={item.status == 'complete'}
        onChange={() => onChecklistItemAction?.({ type: 'manageCompletion', checklistItem: item })}
      />
      <Checklist.MainCell crossedOut={item.cancelled == true}>{item.name}</Checklist.MainCell>
      <Checklist.NoteCell>{item.note}</Checklist.NoteCell>
      <STChecklistItemLink
        item={item}
        document={document}
        incompleteReason={incompleteReason}
        onChecklistItemAction={onChecklistItemAction}
      />
      <Checklist.ActionGroup>
        <Checklist.Button
          tip="Edit checklist item"
          onClick={() => onChecklistItemAction({ type: 'edit', checklistItem: item })}
        >
          <PencilSimpleIcon />
        </Checklist.Button>
        <Checklist.Button
          tip="Delete checklist item"
          onClick={() =>
            onChecklistItemAction({
              type: 'delete',
              checklistItem: item
            })
          }
        >
          <TrashIcon />
        </Checklist.Button>
      </Checklist.ActionGroup>
    </Checklist.Row>
  )
}

function STChecklistItemLink({
  item,
  document,
  incompleteReason,
  onChecklistItemAction
}: {
  item: STChecklistItem
  document: STDocument | undefined
  incompleteReason: STMissingReason | undefined
  onChecklistItemAction: (action: ChecklistItemAction) => void
}) {
  if (document) {
    return (
      <Checklist.LinkCell>
        <a
          className="flex cursor-pointer items-center gap-2 truncate text-blue-500"
          onClick={() =>
            onChecklistItemAction({
              type: 'selectDocument',
              checklistItem: item,
              document: document
            })
          }
          // onClick={() => onSelectDocument(document)}
        >
          <LinkIcon className="h-4 w-4 min-w-4" />
          {document.name}
        </a>
      </Checklist.LinkCell>
    )
  } else if (incompleteReason) {
    return (
      <Checklist.LinkCell>
        <div className="rounded-sm bg-blue-200 px-1 text-sm text-blue-700">
          {incompleteReason.name}
        </div>
      </Checklist.LinkCell>
    )
  } else {
    return <Checklist.LinkCell>{formatConstraintMissing(item.constraint)}</Checklist.LinkCell>
  }
}

function ConfirmDeleteChecklistItemDialog({
  folderId,
  checklistItem,
  onClose
}: {
  folderId: string
  checklistItem: STChecklistItem
  onClose: () => void
}) {
  const platform = useProcess(platformModule)
  const { sdk } = useAppDeps()

  return (
    <Dialog
      title={'Confirm delete'}
      className="w-96"
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await sdk.send({
                type: 'folders/deleteChecklistItem',
                folderId: folderId,
                checklistItemId: checklistItem.id
              })
              platform.send({
                type: 'showSnackbar',
                message: `Deleted checklist item ${formatChecklistItem(checklistItem)}`
              })
              onClose()
            }}
          >
            Delete
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        Are you sure you want to delete the checklist item {formatChecklistItem(checklistItem)}?
      </div>
    </Dialog>
  )
}

/**
 * If a document is missing vs if some non-document info is missing, the wording should be different.
 */
function formatConstraintMissing(constraint: STChecklistItem['constraint']) {
  return match(constraint)
    .with({ type: 'document_type' }, () => 'Not uploaded')
    .with({ type: 'category' }, () => 'Not filled in')
    .exhaustive()
}
