import { SearchIcon, Select, ToggleSwitch } from '@st/theme'
import {
  QUESTIONNAIRE_STATUS_FILTER_OPTIONS,
  QuestionnareFilterOptionKey,
  STFolderListFilters,
  STFolderListFiltersMessage
} from './st-folder-list-filters-module'

export function STFolderListFiltersBar({
  filters,
  send
}: {
  filters: STFolderListFilters
  send: (message: STFolderListFiltersMessage) => void
}) {
  return (
    <div className="flex h-16 flex-row items-center gap-4">
      <div className="relative w-60 rounded-md shadow-sm">
        <input
          type="text"
          placeholder="Client, email, or tag"
          className="block w-full rounded-md border-0 py-1.5 pl-2 pr-10 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
          value={filters.keywords}
          onChange={(e) =>
            send({ type: 'updateFilters', filters: { ...filters, keywords: e.target.value } })
          }
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <SearchIcon className="h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="flex flex-row items-center justify-start gap-2">
        <label className="text-base">Tax year</label>
        <Select
          options={[2024, 2023, 2022, 2021, 2020]}
          placeholder="All"
          value={filters.year}
          onChange={(year) => send({ type: 'updateFilters', filters: { ...filters, year } })}
          buildLabel={(k) => k}
          buildValue={(v) => v}
        />
      </div>

      <div className="flex flex-row items-center justify-start gap-2">
        <label className="text-base">Return Type</label>

        <Select
          options={['1040', '1120', '1120s', '1065']}
          placeholder="All"
          value={filters.type}
          onChange={(type) => send({ type: 'updateFilters', filters: { ...filters, type } })}
          buildLabel={(k) => k}
          buildValue={(v) => v}
        />
      </div>

      <div className="flex flex-row items-center justify-start gap-2">
        <label className="text-base">Questionnaire status</label>
        <Select
          options={QUESTIONNAIRE_STATUS_FILTER_OPTIONS}
          value={filters.questionnaireStatus}
          onChange={(questionnaireStatus) =>
            send({
              type: 'updateFilters',
              filters: { ...filters, questionnaireStatus: questionnaireStatus }
            })
          }
          placeholder="All"
          buildValue={(v) => v.value as QuestionnareFilterOptionKey}
          buildLabel={(v) => v.label}
        />
      </div>

      <div className="flex flex-row items-center gap-2">
        <label className="text-base">Include archived</label>
        <ToggleSwitch
          value={filters.showArchivedFolders}
          onChange={(value) =>
            send({
              type: 'updateFilters',
              filters: { ...filters, showArchivedFolders: value }
            })
          }
        />
      </div>
    </div>
  )
}
