import { setToggle } from '@st/util/array-set'

import { useProcessState } from '@st/redux'

import { useProcess } from '@st/redux'
import { FolderSummary } from '@st/sdk'
import { Button, GearIcon, SelectableTagOption } from '@st/theme'
import { Dialog, DialogButtons } from '@st/theme/src/Dialog'
import { setDiff } from '@st/util/array-set'
import { useState } from 'react'
import { useAppDeps } from '../app-deps-provider'
import { useSetRoute } from '../app-hooks'
import { stOrganizationModule } from '../st-organizations/st-organization-module'
import { platformModule } from '../st-pdf-viewer/platform-module'
import { formatFolderEntityName } from './st-folder-module'

export function EditFolderTagsDialog({
  folderId,
  tagIds,
  folderEntities,
  onClose
}: {
  folderId: string
  tagIds: FolderSummary['tagIds']
  folderEntities: FolderSummary['entities'] | undefined
  onClose: () => void
}) {
  const { sdk } = useAppDeps()
  const platform = useProcess(platformModule)
  const setRoute = useSetRoute()

  const tags = useProcessState(stOrganizationModule, (s) =>
    (s.state?.folderTags ?? []).filter((t) => !t.deleted)
  )

  const [editedTagIds, setEditedTagIds] = useState(tagIds)

  const [saving, setSaving] = useState(false)

  if (tags.length == 0) {
    return (
      <Dialog
        title="Add tags"
        subtitle="You haven't created any tags yet. To manage tags go to Settings → Tags, or just click the “Manage tags” button."
        buttons={
          <DialogButtons>
            <Button variant="default" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              leadingIcon={<GearIcon className="h-4 w-4" />}
              onClick={() => setRoute({ name: 'manage_organization_tags' })}
            >
              Manage tags
            </Button>
          </DialogButtons>
        }
      />
    )
  }

  return (
    <Dialog
      title={`Add tags for ${formatFolderEntityName(folderEntities)}`}
      subtitle={`Add any of the following tags. You can manage your tags in Settings.`}
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={saving}
            onClick={async () => {
              const diff = setDiff({ old: tagIds, new: editedTagIds })
              // nothing to change
              if (diff.add.length == 0 && diff.remove.length == 0) {
                onClose?.()
              }

              setSaving(true)
              await sdk.send({
                type: 'folders/updateFolderTags',
                folderId: folderId,
                addTagIds: diff.add,
                removeTagIds: diff.remove
              })
              setSaving(false)

              platform.send({
                type: 'showSnackbar',
                message: `Updated tags for ${formatFolderEntityName(folderEntities)}`
              })
              onClose?.()
            }}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        {tags.map((t) => {
          return (
            <SelectableTagOption
              key={t.id}
              color={t.color}
              selected={editedTagIds.includes(t.id)}
              onClick={() => setEditedTagIds(setToggle(editedTagIds, t.id))}
            >
              {t.label}
            </SelectableTagOption>
          )
        })}
      </div>
    </Dialog>
  )
}
