import { DrawOp } from '../draw'
import { addDebugInfo } from './common'
import { Font, Renderer, Text } from './interface'

export const textRenderer: Renderer<Text> = {
  getSize(props, constraints, context) {
    const font: Font = {
      family: props.fontFamily ?? context.defaultFont.family,
      weight: props.fontWeight ?? context.defaultFont.weight,
      style: props.fontStyle ?? context.defaultFont.style
    }
    return context.textSize(processText(props.children), font, props.fontSize)
  },
  render(props, origin, constraints, context) {
    let text = processText(props.children)

    const font: Font = {
      family: props.fontFamily ?? context.defaultFont.family,
      weight: props.fontWeight ?? context.defaultFont.weight,
      style: props.fontStyle ?? context.defaultFont.style
    }

    if (props.overflow == 'ellipsis') {
      text = truncateText(
        text,
        (t) => context.textSize(t, font, props.fontSize).width,
        constraints.width
      )
    }

    const op: DrawOp = {
      type: 'text',
      text: text,
      color: props.color ?? '#000000',
      point: origin,
      fontFamily: font.family,
      fontWeight: font.weight,
      fontStyle: font.style,
      fontSize: props.fontSize,
      lineThrough: props.lineThrough ?? false
    }

    return addDebugInfo(
      op,
      {
        rect: {
          ...origin,
          ...this.getSize(props, constraints, context)
        }
      },
      context.debug?.text
    )
  }
}

function truncateText(text: string, getWidth: (text: string) => number, maxWidth: number) {
  if (text.length == 0) return text

  let curLen = text.length
  let width = getWidth(text.slice(0, curLen))

  if (width <= maxWidth) {
    return text
  }

  while (width > maxWidth && curLen > 0) {
    curLen--
    width = getWidth(text.slice(0, curLen) + '...')
  }

  return text.slice(0, curLen) + '...'
}

function processText(children: string) {
  // TODO: sometimes children is an empty array instead of an empty string
  // investigate why this is the case
  const text = typeof children == 'string' ? children : ''
  return text.trim()
}
